import css from 'styled-jsx/css'
import SwiperCore, { SwiperOptions, Lazy } from 'swiper'

import { iconPath, imagePath, previewImage } from '@ui/helpers'
import { useEffect, useRef } from 'react'
import { thumbImage } from '@libs/client'
import { useIsPreview } from '@ui/hooks'

SwiperCore.use([Lazy])

const sThumb = thumbImage('96x96')

type ImageCarouselProps = {
  imgUrls: string[]
  activeIndex: number
  changedVariantImage: number
  onImageChange: (index: number) => void
  fallbackImgs?: S3Types.fallback
}

const swiperOptions: SwiperOptions = {
  centeredSlides: false,
  // lazy: true,
  loop: true,
  preloadImages: false,
  slidesPerView: 4,
  slideToClickedSlide: true,
  spaceBetween: 16,
  speed: 250,
  watchSlidesVisibility: true,
  breakpoints: {
    320: {
      spaceBetween: 8
    },
    768: {
      spaceBetween: 12
    },
    1120: {
      spaceBetween: 16
    }
  }
}

export default function ImageCarousel({
  imgUrls = [],
  activeIndex = 0,
  changedVariantImage,
  onImageChange,
  fallbackImgs = {}
}: ImageCarouselProps) {
  const isPreview = useIsPreview()
  function onSlideChange() {
    onImageChange(swiperRef.current?.realIndex || 0)
  }
  const swiperRef = useRef<SwiperCore>()

  // const setOptionCaurosel = () => {
  //   if (process.browser) {
  //     const carousel = document.querySelector(`.image-carousel`) as HTMLInputElement
  //     const buttonNavi = document.querySelector(`.swiper-button`) as HTMLInputElement
  //     const offBeforeWidth = (carousel?.clientWidth || 0) - (buttonNavi?.clientWidth || 0) * 2
  //     swiperOptions.width = offBeforeWidth - (window.innerWidth > 768 ? 60 : 50)
  //     swiperOptions.slidesOffsetBefore =
  //       +(offBeforeWidth / 4).toFixed(0) - (window.innerWidth > 768 ? 4 : 10)
  //     swiperRef.current = new SwiperCore('.swiper-container', swiperOptions)
  //     swiperRef.current?.slideToLoop(activeIndex)
  //     swiperRef.current?.on('slideChange', () => onSlideChange())
  //   }
  // }

  useEffect(() => {
    const carousel = document.querySelector(`.image-carousel`) as HTMLInputElement
    const buttonNavi = document.querySelector(`.swiper-button`) as HTMLInputElement
    const offBeforeWidth = (carousel?.clientWidth || 0) - (buttonNavi?.clientWidth || 0) * 2
    swiperOptions.width = offBeforeWidth - (window.innerWidth > 768 ? 60 : 50)
    swiperOptions.slidesOffsetBefore =
      +(offBeforeWidth / 4).toFixed(0) - (window.innerWidth > 768 ? 4 : 10)
    swiperRef.current = new SwiperCore('.swiper-container', swiperOptions)
    swiperRef.current?.slideToLoop(activeIndex)
    swiperRef.current?.on('slideChange', () => onSlideChange())
  }, [])
  // useResize(() => setOptionCaurosel())

  useEffect(() => {
    swiperRef.current?.slideToLoop(activeIndex)
  }, [changedVariantImage])

  return (
    <div className="image-carousel columns is-mobile is-vcentered is-gapless">
      <style jsx>{scopedStyle}</style>
      <div
        className="swiper-button swiper-button__left column is-1 is-paddingless has-text-centered"
        onClick={() => swiperRef.current?.slidePrev()}
      >
        <img src={iconPath('chevron_left_circle_gray.svg')} alt="" />
      </div>
      <div className="column is-10">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {imgUrls.map((imgUrl, idx) => (
              <div key={idx} className="swiper-slide">
                <figure className="image is-square">
                  <picture>
                    <source srcSet={isPreview ? previewImage(imgUrl) : sThumb(imgUrl)} />
                    {!isPreview && fallbackImgs?.[imgUrl] ? (
                      <source
                        srcSet={
                          isPreview ? previewImage(imgUrl) : imagePath(fallbackImgs?.[imgUrl])
                        }
                      />
                    ) : (
                      <></>
                    )}
                    <img
                      src={isPreview ? previewImage(imgUrl) : sThumb(imgUrl)}
                      className="swiper-lazy"
                      alt="swiper lazy"
                    />
                  </picture>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="swiper-button column swiper-button__right is-1 is-paddingless has-text-centered"
        onClick={() => swiperRef.current?.slideNext()}
      >
        <img src={iconPath('chevron_right_circle_gray.svg')} alt="" />
      </div>
    </div>
  )
}

const scopedStyle = css`
  .image-carousel {
    padding: 0 12px;
    .swiper-button {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      height: 100%;
      min-height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 32px;
        height: 32px;
        vertical-align: middle;
      }
    }

    .swiper-lazy-preloader {
      margin: auto;
      width: 50%;
      height: 50%;
      top: 25%;
      left: 25%;
      opacity: 0.5;
    }

    .swiper-container {
      margin: 0 5px;
      padding-top: 5px;
      .swiper-slide {
        border: none;

        img {
          border: solid 1px var(--indigo-4);
          border-radius: 4px;
          object-fit: cover;
        }

        &-active {
          border: solid 1.5px var(--indigo-8);
          border-radius: 6px;
          opacity: 1;
          padding: 3px;
          margin-top: -5px;
          img {
            border: none;
          }
        }

        :hover {
          cursor: pointer;
        }
      }
    }

    @media screen and (min-width: 768px) {
      .swiper-button {
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
`
