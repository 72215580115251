import { page_variants_property_values } from '@libs/common'
import { useIsPreview } from '@ui/hooks'
import css from 'styled-jsx/css'
import { thumbImage } from '@libs/client'
import { previewImage } from '@ui/helpers'
import BorderWraper from './BorderWraper'

type RadioButtonProps = {
  isHorizontal?: boolean
  propertyName: string
  values: page_variants_property_values
  selectedProperty: string
  isShowImage?: boolean
  onPropertyChanged: (property: string) => void
  widthImg?: number
  classVariant?: string
  className?: string
}
const sThumb = thumbImage('96x96')

export default function RadioButton({
  isHorizontal = true,
  propertyName,
  values,
  selectedProperty,
  isShowImage = false,
  widthImg = 60,
  classVariant = '',
  className = '',
  onPropertyChanged
}: RadioButtonProps) {
  const isPreview = useIsPreview()
  const valuesData = Object.keys(values)
  return (
    <div
      className={`radio-buttons radio-btn-style field ${
        isHorizontal ? 'is-horizontal' : ''
      } ${className}`}
    >
      <style jsx global>
        {globalStyle}
      </style>
      <div className="field-label is-normal">
        <label className={`label is-uppercase has-text-left ${isShowImage && 'pb-2'}`}>
          <span className={`${isShowImage && 'title-image'}`}>
            {propertyName}
            {isShowImage && `:`}
          </span>
          {isShowImage && `${selectedProperty}`}
        </label>
      </div>
      <div className="field-body">
        <div className={`field is-grouped is-grouped-multiline ${!isShowImage && 'text-spacing'}`}>
          {[...valuesData].map((value, idx) => (
            <div key={`variant_${idx}`} className="control">
              <BorderWraper
                widthImg={widthImg}
                type={isShowImage ? 'image' : 'text'}
                isActive={valuesData[idx] === selectedProperty}
              >
                <button
                  className={`button is-capitalized ${classVariant} ${
                    isShowImage ? 'btn-img' : 'btn-text'
                  } ${valuesData[idx] === selectedProperty ? 'is-active' : ''}`}
                  style={isShowImage ? { width: `${widthImg}px`, height: `${widthImg}px` } : {}}
                  onClick={() => onPropertyChanged(valuesData[idx])}
                >
                  {isShowImage ? (
                    <img
                      src={
                        isPreview
                          ? previewImage(values?.[value]?.image || '')
                          : sThumb(values?.[value]?.image || '')
                      }
                      // src={imagePath(values?.[value]?.image || '')}
                      alt=""
                      width={widthImg}
                      height={widthImg}
                      className="variant-image"
                    />
                  ) : (
                    value
                  )}
                </button>
              </BorderWraper>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
const globalStyle = css.global`
  .radio-buttons {
    .field-label {
      margin: 0;
      padding: 0 0 8px;
    }
    @media screen and (max-width: 768px) {
      .field-label {
        padding-bottom: 4px;
      }
    }
    .field.is-grouped.is-grouped-multiline {
      align-items: center;
      &.text-spacing {
        margin: 0 -4px;
      }
      .control {
        max-width: 100%;
        margin: 0px 2px 4px;
        .button {
          max-width: 100%;
          text-align: justify;
          white-space: normal;
          font-size: 13px;
          line-height: 18px;

          &.btn-img {
            border: 1px solid #e0e3e9;
            box-sizing: border-box;
            padding: 0;
            border-radius: 4px;

            .variant-image {
              border-radius: 4px;
            }
          }
          &.btn-text {
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            text-align: center;
            color: var(--gray-8);
            width: 100%;
            height: auto;
            padding: 10px 20px;
            border-radius: 6px;
            border: 1px solid var(--indigo-6);

            &.is-active {
              padding: 9px 19px;
              border: none;
            }
            &.is-variant {
              font-size: 13px;
              line-height: 20px;
              padding: 9px 12px;
              min-width: 40px;

              &.is-active {
                padding: 8px 11px;
                min-width: 36px;
              }
            }
          }
        }
      }
    }

    .label {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: var(--gray-8);

      letter-spacing: 0.09em;

      .title-image {
        font-weight: 400;
        padding-right: 4px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .radio-btn-style {
      display: block !important ;
    }

    .radio-buttons {
      .field.is-grouped.is-grouped-multiline {
        .control {
          max-width: 100%;
          margin: 0px 4px 8px;
          .button {
            &.btn-text {
              &.is-variant {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
`
