import css from 'styled-jsx/css'
import { iconPathBvf } from '@ui/helpers'

interface BorderWrapperProps extends Types.PlainObject {
  type?: 'text' | 'image'
  isActive?: boolean
  widthImg?: number
}

export default function BorderWrapper({
  type = 'text',
  widthImg = 60,
  isActive,
  ...props
}: BorderWrapperProps) {
  return (
    <>
      <div
        {...props}
        className={`border-wrapper ${type} ${isActive ? 'variant-active' : ''}`}
        style={isActive ? { width: `${widthImg + 10}px`, height: `${widthImg + 10}px` } : {}}
      >
        <style jsx global>
          {borderStyle}
        </style>
        <img src={iconPathBvf('conner_checked.svg')} alt="" className="conner-checked-style" />
        {type === 'text' && props.children}
      </div>
      {type === 'image' && props.children}
    </>
  )
}

const borderStyle = css.global`
  .border-wrapper {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    border-radius: 6px;
    &.text {
      padding: 0px;
    }
    .conner-checked-style {
      position: absolute;
      top: -1px;
      right: -1px;
      z-index: 1;
      width: 32px;
      height: 32px;
      display: none;
    }
    &.variant-active {
      position: absolute;
      width: 70px;
      height: 70px;
      top: -5px;
      right: -5px;
      z-index: 10;
      border: 2px solid var(--primary-100);
      padding: 3px;
      .conner-checked-style {
        display: block;
      }
      &.text {
        .button {
          background: var(--primary-10);
          border-width: 0;
        }
        .conner-checked-style {
          top: 0px;
          right: 0px;
        }
      }
    }
    &.text {
      position: static;
      width: auto !important;
      height: auto !important;
      padding: 0;
    }
  }
`
