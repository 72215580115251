import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import css from 'styled-jsx/css'
import { logger } from '@ui/analytics'
import ProductForm from '@ui/components/product/ProductForm'
import ProductImages from '@ui/components/product/ProductImages'
import Reviews from '@ui/components/product/Reviews'
import Description from '@ui/components/shared/Description'
import Footer from '@ui/components/shared/Footer'
import Header from '@ui/components/shared/Header'
import { isFullSessionDomain } from '@ui/helpers'
import {
  useFetchSellPage,
  useSetting,
  useSettingDefault,
  useSyncedState,
  useVersionCookie
} from '@ui/hooks'
import { useNotiContext } from '@ui/components/shared/Notification'
import Loading from '@ui/components/shared/Loading'
import { isPayingWithPaypalKey, useGlobalState } from '@libs/client'
import getLogRocket from '@ui/analytics/logRocket'
const IndexPage: NextPage = () => {
  const [storeSetting] = useSetting('store')
  const settingDefault = useSettingDefault()
  const [sellpageSetting, setStore] = useSetting('sellpage')
  const [isPayingWithPaypal] = useGlobalState<boolean>(isPayingWithPaypalKey)
  const [, , getActiveImageSyncedProp] = useSyncedState<number>(0)
  const [changedVariantImage, , getChangedVariantImageSyncedProp] = useSyncedState<number>(0)
  const router = useRouter()
  const subpath = router.query.subpath?.toString()
  const version = useVersionCookie()
  const error = router.query.error?.toString()
  const { notiDispatch } = useNotiContext()
  const [loading] = useFetchSellPage({ storeSetting, sellpageSetting, setStore })

  useEffect(() => {
    if (version === 'bv10') {
      logger.logProductEvent('version_bv10')
    }
    if (version === 'bv11') {
      logger.logProductEvent('version_bv11')
    }
    if (version === 'bv12') {
      logger.logProductEvent('version_bv12')
    }
    if (version === 'bv13') {
      logger.logProductEvent('version_bv13')
    }
    if (version === 'bv14') {
      logger.logProductEvent('version_bv14')
    }
  }, [version])

  useEffect(() => {
    if (
      isFullSessionDomain((settingDefault?.setting?.settings.general?.logrocket || '').split(','))
    ) {
      getLogRocket().logRocket?.init()
    }
  }, [])
  useEffect(() => {
    logger.logProductEvent('view_product')
    if (error === 'offer-expired') {
      notiDispatch({
        payload: {
          content: 'Your order is expired!',
          type: 'is-warning',
          removable: false,
          timeout: 1000
        },
        type: 'REMOVE_ALL_AND_ADD'
      })
      router.replace('/')
    }
  }, [])
  useEffect(() => {
    if (!loading) {
      if (subpath && !sellpageSetting?.settings) {
        window.location.href = `/${window.location.search}`
      }
    }
  }, [loading])
  return (
    <div className="product-page">
      <style jsx global>
        {globalStyle}
      </style>
      {isPayingWithPaypal ? (
        <Loading isFullWidth />
      ) : (
        <>
          {loading ? (
            <Loading isFullWidth />
          ) : sellpageSetting && sellpageSetting.settings ? (
            <>
              <Header haveSaleBar />
              <div className="wrapper">
                <Description
                  description={sellpageSetting?.parts?.content?.top}
                  className="section section--small top-description"
                />
                <div className={`product container-beva ${isPayingWithPaypal ? 'is-hidden' : ''}`}>
                  <div className="columns">
                    <div className="column is-half product__image-caurosel">
                      <ProductImages
                        activeIndexSyncedProp={getActiveImageSyncedProp()}
                        changedVariantImage={changedVariantImage}
                      />
                    </div>
                    <div
                      className={`column is-half ${version} ${
                        version == 'bv11' ||
                        version == 'bv12' ||
                        version == 'bv13' ||
                        version == 'bv14'
                          ? 'version-enhancement'
                          : ''
                      }`}
                    >
                      <ProductForm
                        activeImageSyncedProp={getActiveImageSyncedProp()}
                        changedVariantImageSyncedProp={getChangedVariantImageSyncedProp()}
                        version={version}
                      />
                    </div>
                  </div>
                </div>
                <Description
                  description={sellpageSetting?.parts?.content?.middle}
                  label="Description"
                  labelMobile=""
                  isCanShowMore={true}
                  className="container-beva container-beva--medium middle-description"
                />
              </div>
              <div className="line"></div>
              <div className="wrapper">
                <Reviews className="container-beva container-beva--medium" />
              </div>
              <Footer />
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  )
}
const globalStyle = css.global`
  .product-page {
    .top-description {
      margin-bottom: 61px;
    }
    .product {
      padding-top: 0;
      padding-bottom: 24px;
      &__image-caurosel {
        .product-images {
          margin: 0 -0.75rem;
        }
      }
      .version-enhancement {
        padding-top: 0px;
      }
    }
    .line {
      width: 100%;
      background-color: var(--gray-3);
      height: 16px;
      padding-top: 16px;
    }
    @media screen and (min-width: 768px) {
      .product {
        padding-top: 40px;
        padding-bottom: 48px;
        border-bottom: 1px solid var(--indigo-4);
        &__image-caurosel {
          .product-images {
            margin: 0;
          }
        }
      }
      .line {
        display: none;
      }
    }
  }
`
export default IndexPage
