import { useState } from 'react'
import css from 'styled-jsx/css'
import { imagePath, previewImage } from '@ui/helpers'
import { useIsPreview } from '@ui/hooks'
import getConfig from 'next/config'
import { iconPath } from '@ui/helpers'

type ReviewsImagesProps = {
  source: string
}

export const ReviewImage: React.FC<ReviewsImagesProps> = ({ source }) => {
  const [isZoomed, setIsZoomed] = useState(false)
  const isPreview = useIsPreview()

  const toggle = () => {
    setIsZoomed(!isZoomed)
  }
  const config = getConfig().publicRuntimeConfig

  return (
    <>
      <style jsx>{imgZoomStyle}</style>
      <div className={`zoom-container ${isZoomed ? `zoomout` : `zoomin`}`} onClick={toggle}>
        {!source.includes('.mp4') ? (
          <img
            className="review-content"
            src={isPreview ? previewImage(source) : imagePath(source)}
            title="Review image"
            alt="icon"
            role="presentation"
          />
        ) : (
          <>
            {!isZoomed ? (
              /* eslint-disable */
              <video className="review-content" autoPlay loop muted playsInline>
                <source src={`${config.cdnVideo || ''}${source}`} type="video/mp4" />
              </video>
            ) : (
              <video className="review-content" controls loop playsInline>
                <source src={`${config.cdnVideo || ''}${source}`} type="video/mp4" />
              </video>
            )}
          </>
        )}
        <img
          src={iconPath('zoom.svg')}
          className={`${isZoomed ? `zoom-svg-out` : `zoom-svg-in`}`}
          alt="icon"
        />
      </div>
    </>
  )
}

const imgZoomStyle = css`
  .zoom-container {
    position: relative;
    display: inline-block;
  }
  .zoomin {
    img,
    video {
      height: auto;
      width: 110px;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      cursor: pointer;
    }

    .zoom-svg-in {
      width: 24px;
      height: 24px;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      cursor: pointer;

      position: absolute;
      right: 4px;
      bottom: 12px;
    }
  }
  .zoomout {
    img,
    video {
      width: 455px;
      height: auto;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      cursor: pointer;
    }

    .zoom-svg-out {
      width: 40px;
      height: 40px;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      cursor: pointer;

      position: absolute;
      right: 4px;
      bottom: 12px;
    }
  }

  .review-content {
    border-radius: 4px;
  }
`
