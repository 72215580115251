import css from 'styled-jsx/css'
import { useCallback, useEffect, useState } from 'react'
import ImageCarousel from '@ui/components/shared/ImageCarousel'
import { SyncedProp, useSetting, useThrottle, useVersionCookie } from '@ui/hooks'
import { thumbImage } from '@libs/client/helpers/imagePath'
import { imagePath } from '@ui/helpers'

type ProductImagesProps = {
  changedVariantImage: number
  activeIndexSyncedProp: SyncedProp<number>
}

function hashCode(str: string) {
  return str
    .split('')
    .reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0)
}

const sThumb = thumbImage('96x96')
// const mThumb = thumbImage('w=500,h=500')

export default function ProductImages({
  changedVariantImage,
  activeIndexSyncedProp
}: ProductImagesProps) {
  const [sellpageSetting] = useSetting('sellpage')
  const [syncedActiveIndex, setSyncedActiveIndex] = activeIndexSyncedProp
  const imgUrls = sellpageSetting?.gallery || []
  const version = useVersionCookie()
  const [isMounted, setIsMounted] = useState(false)
  const throttledSyncedActiveIndex = useThrottle(syncedActiveIndex, 60)
  useEffect(() => {
    setIsMounted(true)
    setSyncedActiveIndex(0)
  }, [])
  const changeImage = (index: number) => {
    setSyncedActiveIndex(index)
  }
  const getImageThumb = useCallback(
    (index: number) => {
      return sThumb(imgUrls[index])
    },
    [imgUrls]
  )
  const getImageSrc = useCallback(
    (index: number) => {
      const image = imgUrls[index]
      return imagePath(image)
      // if (image.endsWith('.gif')) {
      // } else {
      //   return mThumb(image)
      // }
    },
    [imgUrls]
  )

  const mapImageWithDescribe: { [key: string]: string } = {
    'p/2d13drdcbzdv4yond9oja1pua/i/304tkzhmpwl2kujjbtxs2jmnm_1.jpg':
      'Reusable Lift up|Invisible bra tape',
    'p/2d13drdcbzdv4yond9oja1pua/i/7996lyuvg6ifcsh4su7q0dt7f_2.jpg': 'Instant Push up',
    'p/2d13drdcbzdv4yond9oja1pua/i/5oqh3vquy51dzg41xo1zxqxmg_3.jpg':
      'Works for all sizes|Lift up your boobs & create natural cleavage',
    'p/2d13drdcbzdv4yond9oja1pua/i/6la77jq76av20s84djsvtnje0_4.jpg':
      'Perfectly sticky|Medical-grade silicone with nipples cover & lift up',
    'p/2d13drdcbzdv4yond9oja1pua/i/1ln6uxosp3hlywonyl6gd06r7_5.jpg':
      'Seamlessly invisible|Can be worn underneath various tops and hid well',
    'p/2d13drdcbzdv4yond9oja1pua/i/1u0cbi378q1lmfkmknbvffu04_6.jpg':
      'Waterproof adhesive|Medical-grade silicone with nipples cover & lift up',
    'p/2d13drdcbzdv4yond9oja1pua/i/h8b7ctv3gdpdmknx03gqxa8q_7.jpg':
      'Skin friendly|Adopt medical-graded material to prevent redness'
  }

  const getImageSrcFallback = useCallback(
    (index: number) => {
      const imageFallback = sellpageSetting?.fallback_img?.[imgUrls[index]] || ''

      return imagePath(imageFallback)
    },
    [imgUrls]
  )

  return (
    <div
      className={`product-images ${
        version == 'bv11' || version == 'bv12' || version == 'bv13' || version == 'bv14'
          ? 'version-enhancement'
          : ''
      }`}
    >
      <style jsx global>
        {globalStyle}
      </style>
      <figure className="product-thumbnail is-square is-marginless mb-3 image">
        {version == 'bv12' ? <div className="sale-badge">SALE 80%</div> : ''}
        {imgUrls[syncedActiveIndex] && (
          <picture key={throttledSyncedActiveIndex}>
            <source data-srcset={getImageSrc(syncedActiveIndex)} />
            {getImageSrcFallback(syncedActiveIndex) ? (
              <source data-srcset={getImageSrcFallback(syncedActiveIndex)} />
            ) : (
              <></>
            )}
            <img
              className={`lazyload ${hashCode(imgUrls[syncedActiveIndex])}`}
              src={getImageThumb(syncedActiveIndex)}
              data-src={getImageSrc(syncedActiveIndex)}
              alt="carousel"
            />
          </picture>
        )}
        {(version == 'bv11' || version == 'bv12' || version == 'bv13' || version == 'bv14') &&
        mapImageWithDescribe[imgUrls[syncedActiveIndex]] ? (
          <div className={`describe-image ${version == 'bv14' ? 'bv14' : ''}`}>
            {mapImageWithDescribe[imgUrls[syncedActiveIndex]].split('|').map((text, index) => (
              <p
                className={`${index == 0 ? 'describe-image__big' : 'describe-image__small'}`}
                key={`text_${index}`}
              >
                {text}
              </p>
            ))}
          </div>
        ) : (
          ''
        )}
      </figure>
      {isMounted && (
        <ImageCarousel
          fallbackImgs={sellpageSetting?.fallback_img}
          imgUrls={imgUrls}
          activeIndex={syncedActiveIndex}
          changedVariantImage={changedVariantImage}
          onImageChange={changeImage}
        />
      )}
    </div>
  )
}

const globalStyle = css.global`
  .version-enhancement .product-thumbnail {
    margin-bottom: 6px !important;

    @media screen and (min-width: 768px) {
      margin-bottom: 12px !important;
    }
  }
  .product-images {
    .product-thumbnail {
      margin-top: -0.75rem;
      margin-right: -0.75rem;
      margin-left: -0.75rem;

      img {
        object-fit: contain;
        background-color: white;
      }
      .sale-badge {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
        letter-spacing: 0.02em;

        padding: 6px 16px;
        background-color: var(--red-600);

        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
      }
      .describe-image {
        color: var(--gray-8);

        background-color: #e0c0ff;
        padding: 8px;
        width: 100%;
        text-align: center;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;

        &__big {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
        }
        &__small {
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
        }
      }
      .bv14 {
        .describe-image__big {
          font-size: 20px;
        }
        .describe-image__small {
          font-size: 14px;
          line-height: 20px;
        }

        @media screen and (min-width: 321px) {
          padding-top: 10px;
          padding-bottom: 6px;

          .describe-image__big {
            font-size: 20px;
            line-height: 26px;
          }
          .describe-image__small {
            font-size: 14px;
            line-height: 24px;
          }
        }
        @media screen and (min-width: 414px) {
          padding-top: 9px;
          padding-bottom: 8px;

          .describe-image__big {
            font-size: 20px;
            line-height: 26px;
          }
          .describe-image__small {
            font-size: 15px;
            line-height: 24px;
          }
        }
      }
    }

    @media screen and (min-width: 768px) {
      margin-right: calc((100% - 120px) * 0.02);

      .product-thumbnail {
        .sale-badge {
          font-size: 20px;
          padding: 10px 22px;
        }

        .describe-image {
          padding: 12px 8px 10px;

          &__big {
            font-weight: 600;
            font-size: 22px;
            line-height: 25px;
          }
          &__small {
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
          }
        }
      }
    }
    @media screen and (min-width: 992px) {
      margin-right: calc((100% - 120px) * 0.083);
    }
  }
`
