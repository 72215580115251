import { iconPath } from '@ui/helpers'
import { useRatings, useReviews, useSetting } from '@ui/hooks'
import { FC, useEffect, useState } from 'react'
import css from 'styled-jsx/css'
import StarRating from '../shared/StarRating'
import ReviewModal from './ReviewModal'
import { ReviewImage } from './ReviewImage'
import { logger } from '@ui/analytics'
import axios from 'axios'
import { RatingsResponse } from '@libs/server/apis/ratings'
import { page_review } from '@libs/common'

type ReviewsProps = {
  perPage?: number
  className?: string
}

const Reviews: FC<ReviewsProps> = ({ perPage = 3, className }: ReviewsProps) => {
  const [sellpageReviews, setSellpageReviews] = useState<page_review[]>([])
  const [sellpageSetting] = useSetting('sellpage')
  const [isLoading, setIsLoading] = useState(false)
  const [reviews] = useReviews()
  const [sellpageRating] = useRatings()
  const [isOpen, setIsOpen] = useState(false)
  const [activePage, setActivePage] = useState(1)

  const handleFetchRatings = async (take: number) => {
    try {
      setIsLoading(true)
      const _data: RatingsResponse = (
        await axios.get(
          `/api/pages/${sellpageSetting?.settings.general?.id}/ratings?skip=3&take=${take}`
        )
      )?.data
      setSellpageReviews([...sellpageReviews, ..._data?.sellpage_reviews])
    } catch (err: any) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setSellpageReviews([
      ...(reviews || []),
      ...((sellpageRating?.user_ratings as page_review[]) || [])
    ])
  }, [])

  const showMore = async () => {
    await handleFetchRatings(perPage * (activePage + 1))
    setActivePage(activePage + 1)
    logger.logProductEvent('show_reviews')
  }

  const toggleModal = () => setIsOpen(!isOpen)

  return (
    <>
      {!!sellpageReviews.length && (
        <div className={`sellpage-reviews ${className}`}>
          <style jsx>{scopedStyle}</style>
          <style jsx>{imgZoomStyle}</style>
          <div className="review-content">
            <div className="review-content__box">
              <div className="review-content__title">Reviews</div>

              <div className="review-content__rating-button">
                <div className="review-content__rating">
                  <div className="sell-page-reviews--title">{sellpageRating?.avgRated}</div>
                  <div className="rating-number-content">
                    <StarRating rating={sellpageRating?.avgRated} className="style-inreview" />
                    <div className="text-rating">
                      Based on <span>{sellpageRating?.totalRating} ratings</span>
                    </div>
                  </div>
                </div>
                <button
                  className="sell-page-reviews--button button--cta button is-primary is-fullwidth-mobile"
                  onClick={toggleModal}
                >
                  <img src={iconPath('pen.svg')} alt="pen" className="edit-icon" />
                  <div className="write-your-review">Write your review</div>
                </button>
              </div>
            </div>

            <div>
              {sellpageReviews?.length > 0 &&
                sellpageReviews.map((review, idx: number) => (
                  <div key={`review-${idx}`} className="review-item">
                    <div className="review-box">
                      <div className="review-box--name">{review?.name}</div>
                      <StarRating rating={review?.rating} className="style-inreview-item" />
                    </div>
                    <div className="review-item__content">
                      <div className="review-box--verify">
                        <img
                          className="review-box--checked"
                          src={iconPath('check_green.svg')}
                          alt="check"
                        />
                        <small>Verified Buyer</small>
                      </div>

                      <div className="review-item-content">
                        <div className="review-item-content--title">{review?.title ?? ''}</div>
                        <div className="reverse-content">
                          <div className="review-item-content--content">
                            {review?.description ?? ''}
                          </div>

                          {review?.images?.length >= 1 && (
                            <div className="review-item-content--image">
                              <ReviewImage source={review?.images[0]} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <hr className="m-0 review-item-content--divider" />
                  </div>
                ))}
            </div>
            <div className="show-more-button">
              <button
                className={`button button--cta is-primary is-outlined 
                ${perPage * activePage >= sellpageRating?.totalRating ? 'is-hidden' : ''} 
                ${isLoading ? 'is-loading' : ''}`}
                onClick={showMore}
              >
                Show more reviews
              </button>
            </div>
          </div>
        </div>
      )}
      <ReviewModal open={isOpen} toggleModal={toggleModal} />
    </>
  )
}

const scopedStyle = css`
  .sellpage-reviews {
    padding-top: 32px;
    padding-bottom: 72px;

    .sell-page-reviews {
      &--button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;
        cursor: pointer;
        border: none;
        max-width: 450px;

        .edit-icon {
          width: 18px;
          height: 18px;
          margin-right: 12px;
        }
      }

      &--title {
        font-weight: 700;
        margin-right: 16px;

        font-weight: 600;
        font-size: 48px;
        line-height: 62px;
      }
    }

    .write-your-review {
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      color: var(--white-100);
    }

    .text-rating {
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      color: gray(--gray-8);

      span {
        font-weight: 600;
      }
    }

    .review-content {
      &__box {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__title {
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: var(--gray-8);

        margin-bottom: 24px;
      }
      &__rating-button {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__rating {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
      }
    }

    .review-item {
      padding-top: 24px;

      .review-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--name {
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;

          color: var(--gray-9);
        }

        &--verify {
          display: flex;
          align-items: center;
          height: 22px;
          small {
            font-size: 12px;
            line-height: 22px;
            color: var(--gray-8);
          }
        }

        &--checked {
          width: 14px;
          height: 14px;
          margin-right: 6px;
        }
      }
      &:last-child {
        .review-item-content--divider {
          display: none;
        }
      }
    }
    .review-item-content {
      margin-top: 16px;
      &--content {
        margin-bottom: 16px;

        font-weight: 400;
        font-size: 15px;
        line-height: 24px;

        color: var(--gray-8);
      }

      &--title {
        margin-bottom: 8px;

        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: var(--gray-9);
      }

      &--image {
        margin-bottom: 24px;
      }

      &--divider {
        background-color: var(--indigo-3);
      }
    }

    .show-more-button {
      margin-top: 38px;
      width: 100%;
      button {
        padding-top: 12px;
        padding-bottom: 12px;
        width: 100%;
        border-radius: 4px;
        border: 1px solid #818181;

        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: var(--gray-8);

        &:hover,
        &:focus {
          background-color: transparent !important;
          color: var(--text) !important;
        }

        &.is-loading {
          background-color: white !important;
          color: white !important;
        }
      }
    }

    .button.is-primary.is-outlined.is-loading::after {
      border-color: transparent transparent #1b1b1b #1b1b1b !important;
    }

    :global(.style-inreview) {
      height: 24px;
    }

    :global(.style-inreview-item) {
      height: 16px;
    }
  }

  @media screen and (min-width: 768px) {
    .sellpage-reviews {
      padding-top: 40px;
      padding-bottom: 98px;

      .review-content {
        &__box {
          flex-direction: row;
          padding-bottom: 24px;
          border-bottom: 1px solid var(--indigo-4);

          .sell-page-reviews {
            &--button {
              width: 228px;
            }
          }
        }
        &__rating-button {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 80%;
        }
        &__title {
          width: 20%;
          text-align: left;
        }
      }

      .sell-page-reviews {
        &--title {
          font-size: 61px;
        }
      }

      .review-item {
        padding-top: 24px;

        &__content {
          display: flex;

          .review-item-content {
            width: 80%;
            margin-top: 0px;

            .reverse-content {
              display: flex;
              flex-direction: column-reverse;
            }

            &--content {
              margin-bottom: 24px;
            }
            &--image {
              margin-bottom: 16px;
            }
          }
        }

        .review-box {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &--name {
            width: 20%;
          }

          &--verify {
            width: 20%;
          }
        }
      }
    }

    :global(.style-inreview) {
      height: 28px;
    }
  }
`

const imgZoomStyle = css`
  .zoomin img {
    height: 90px;
    width: 90px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
    cursor: pointer;
  }
  .zoomin img:hover {
    width: 350px;
    height: 350px;
  }
`

export default Reviews
