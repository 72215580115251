import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { thumbImage } from '@libs/client'
import getConfig from 'next/config'
import { logger } from '@ui/analytics'
import { useSetting } from '@ui/hooks'

interface DescriptionProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string
  description?: string
  labelMobile?: string
  isCanShowMore?: boolean
}
type SeeMoreState = 'default' | 'collapse' | 'expand'

const sThumb = thumbImage('96x96')

const cdnBasePath = getConfig().publicRuntimeConfig.cdnBasePath
const shortCdnBasePath = cdnBasePath?.replace('https://', '') ?? ''

const Description: FC<DescriptionProps> = ({
  label = '',
  description = '',
  className = '',
  labelMobile = '',
  isCanShowMore = false
}) => {
  const ref = useRef(null)
  const buttonSeeMoreRef: any = useRef<HTMLDivElement>()
  const [maxHeightContent, setMaxHeightContent] = useState<string>('532px')
  const [isSeeMoring, setIsSeeMoring] = useState<SeeMoreState>('default')
  const [isFirst, setIsFirst] = useState(true)
  const [sellpageSetting] = useSetting('sellpage')
  const fallbackImg = sellpageSetting?.fallback_img
  useEffect(() => {
    window.addEventListener('touchstart', () => {
      const videoElement: any = document.getElementsByTagName('video')
      if (videoElement.length) {
        videoElement.forEach(async (element: HTMLMediaElement) => {
          if (!element.controls) {
            element.play()
          }
        })
      }
    })
    return () => {
      window.removeEventListener('touchstart', () => {
        console.log('clean')
      })
    }
  }, [ref])

  const handleExpandContent = () => {
    const maxContent = document?.querySelector('.max-content')?.clientHeight || 0
    if (isSeeMoring === 'default' || isSeeMoring === 'expand') {
      setMaxHeightContent(isSeeMoring === 'default' ? 'auto' : `${maxContent}px`)
      setIsSeeMoring('collapse')
      logger.logProductEvent('see_more')
    } else {
      setIsSeeMoring('expand')
      setMaxHeightContent('532px')
      window?.scrollBy({
        top: -maxContent + 420,
        left: 0
      })
      logger.logProductEvent('see_less')
    }
  }

  useEffect(() => {
    if (isFirst) {
      logger.logProductEvent('view_see_more')
      setIsFirst(false)
    }
  }, [isFirst])

  useEffect(() => {
    // const isMobile = window.innerWidth < 768
    // if (isMobile) handleExpandContent()
    handleExpandContent()
  }, [])

  const editedDescription = useMemo(() => {
    return (
      description
        ?.replace(/http:\/\//gm, 'https://')
        // .replace(/(<img([^>]*))( src=)/gm, '$1 alt="" data-src=')
        ?.replace(/<video([\S\s]*?)>([\S\s]*?)<\/video>/g, (u: string) => {
          const video = (u || '').replace(`controls="controls"`, (x) => {
            if (u.includes('#audio')) return x
            return 'autoplay loop muted playsinline'
          })
          return video
        })
        .replace(/<img([\w\W]+?)>/g, (_a) => {
          let src = ''
          let originSrc = ''
          const imgTag = _a.replace(/src="([^'"]*)"/g, (_x, y: string) => {
            originSrc = y
            const image = y
              .replace(cdnBasePath, '')
              .replace(shortCdnBasePath, '')
              .split('/')
              .filter((z) => z)
              ?.join('/')
            y = y.includes('https://') ? y : `https://${y}`
            src = y
            return `class="lazyload blur-up lazypreload" alt="" data-src="${y}" src="${sThumb(
              image
            )}"`
          })
          const fallback = fallbackImg?.[originSrc]
          const pictureTag = `<picture>
            <source data-srcset="${src}" />
            ${
              fallback
                ? `<source data-srcset="${
                    fallback.includes('https://') ? fallback : `https://${fallback}`
                  }" />`
                : ''
            }
            ${imgTag}
        </picture>`
          return pictureTag
        })

        .replace(/<iframe/g, '<figure class="image is-16by9"><iframe class="has-ratio"')
        .replace(/<\/iframe>/g, '</iframe></figure>')
    )
  }, [description])

  if (!description) return null

  return (
    <div className={`description ${className || ''}`} ref={ref}>
      {isCanShowMore && <div className="custom-border custom-border__top" />}
      {label && (
        <div className="columns">
          <div className="column is-one-fifth is-hidden-mobile">
            <div className={`description-label ${isCanShowMore && 'label-middle'}`}>{label}</div>
          </div>
          <div className="column is-four-fifth">
            {labelMobile && <div className="description-label__mobile ">{labelMobile}</div>}
            <div
              className={`description-content ${
                isSeeMoring === 'collapse' ? 'description-content__transition' : ''
              }`}
              style={{ height: maxHeightContent }}
            >
              <div
                // v-lazy-container="{ selector: 'img' }"
                className={`rich-text ${isCanShowMore && 'max-content'} `}
                dangerouslySetInnerHTML={{
                  __html: editedDescription
                }}
              />
              <div
                className="bg-transparent-radiant"
                style={{
                  display: isSeeMoring === 'collapse' ? 'none' : 'block'
                }}
              />
            </div>
            {isCanShowMore && (
              <button className="btn-see-more" ref={buttonSeeMoreRef} onClick={handleExpandContent}>
                {isSeeMoring !== 'collapse' ? `See more` : `See less`}
              </button>
            )}
          </div>
        </div>
      )}
      {isCanShowMore && <div className="custom-border custom-border__bottom" />}
      {!label && (
        <div
          // v-lazy-container="{ selector: 'img' }"
          className="rich-text"
          dangerouslySetInnerHTML={{
            __html: editedDescription
          }}
        />
      )}
      <style jsx>{`
        .middle-description {
          .columns {
            margin-top: 0px;
            margin-bottom: 0px;
          }
        }
        .description-content {
          position: relative;
          height: 532px;
          overflow: hidden;

          &__transition {
            transition: height 0.5s;
          }
        }

        .bg-transparent-radiant {
          height: 420px;
          width: 100%;
          background: linear-gradient(0deg, #ffffff 9.32%, rgba(255, 255, 255, 0) 100%);
          position: absolute;
          bottom: 0px;
        }
        .btn-see-more {
          padding: 12px 24px;
          background: var(--white-100);
          border: 1px solid var(--gray-8);
          border-radius: 4px;
          width: 100%;

          font-weight: 600;
          font-size: 16px;
          line-height: 28px;
          text-align: center;
          color: var(--gray-8);
          cursor: pointer;

          margin-top: 20px;
          margin-bottom: 40px;
        }
        .description-label {
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          color: var(--gray-8);

          &__mobile {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.09em;
            text-transform: uppercase;
            font-weight: 600;
            color: var(--gray-9);
            margin-bottom: 24px;
          }
        }
        @media screen and (min-width: 768px) {
          .description-label {
            &__mobile {
              display: none;
            }
          }

          .btn-see-more {
            margin-bottom: 128px;
          }
          .custom-border {
            width: 100%;
            margin: auto;
            border-bottom: 1px solid var(--indigo-4);

            &__top {
              margin-bottom: 48px;
              border: 0;
            }
          }
        }
      `}</style>
    </div>
  )
}

export default Description
