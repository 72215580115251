/* eslint-disable react-hooks/rules-of-hooks */
import {
  SyncedProp,
  useCookieState,
  useDeepEffect,
  useEventListener,
  useFbp,
  useMounted,
  usePaygates,
  usePinterest,
  useRatings,
  useRedirect,
  useResize,
  useSetting,
  useSizeMapping,
  useSnapChat,
  useSyncedState,
  useTtq
} from '@ui/hooks'
import { useEffect, useMemo, useState } from 'react'
import * as immutable from 'object-path-immutable'
import Countdown from '@ui/components/shared/Countdown'
import DropdownSelect from '@ui/components/shared/DropdownSelect'
import QuantityInput from '@ui/components/shared/QuantityInput'
import RadioButton from '@ui/components/shared/RadioButton'
import RandomNumber from '@ui/components/shared/RandomNumber'
import css from 'styled-jsx/css'
import { countItems, getCurrency, iconPath, iconPathBvf, isEmpty } from '@ui/helpers'
import { logger } from '@ui/analytics'
import { useNotiContext } from '../shared/Notification'
import { useRouter } from 'next/router'
import {
  genSid,
  maxItemNegative,
  maxQuantityDiscountProduct,
  isShowCartModalKey,
  preCalcOrder
} from '@libs/client/helpers'
import {
  useGlobalState,
  useLocalIsPuchase,
  useLocalOrder,
  useOrderService,
  usePriceChangedItems,
  useTrackingService
} from '@libs/client'
// import CartModal from './cart/CartModal'
import Description from '../shared/Description'
import { useGtag } from '@ui/hooks/useGtag'
import { page_variants_items } from '@libs/common'
import getLogRocket from '@ui/analytics/logRocket'
import StarRating from '../shared/StarRating'
import SizeCalculator from '../size/SizeCalculator'

type ProductFormProps = {
  activeImageSyncedProp: SyncedProp<number>
  changedVariantImageSyncedProp: SyncedProp<number>
  isPayingWithPaypalSyncedProp?: SyncedProp<boolean>
  version: string
}

interface ISelectVariantOptions {
  changeImage?: boolean
  redirect?: boolean
}

function initProperties(properties: S3Types.page_variants_properties) {
  const init: S3Types.item_properties = {}
  Object.keys(properties).forEach((key) => {
    init[key] = Object.entries(properties[key]?.values ?? {})?.[0]?.[1]?.code ?? ''
  })
  return init
}

function compareProperties(props1: S3Types.item_properties, props2: S3Types.item_properties) {
  let isEqual = true

  Object.keys(props1).forEach((key) => {
    if (props1[key] !== props2[key]) {
      isEqual = false
    }
  })

  return isEqual
}

export function findVariant(
  properties: S3Types.item_properties,
  variantItems: S3Types.page_variants_items,
  isOrigin = false
) {
  const variantIds = Object.keys(variantItems)
  const foundVariantId = variantIds.find((variantId) =>
    compareProperties(
      properties,
      isOrigin
        ? variantItems[variantId].origin_properties ?? {}
        : variantItems[variantId].properties ?? {}
    )
  )
  return foundVariantId ? variantItems[foundVariantId] : undefined
}

export default function ProductForm({
  activeImageSyncedProp,
  changedVariantImageSyncedProp,
  version
}: ProductFormProps) {
  const router = useRouter()
  const mounted = useMounted()
  const [sellpageSetting, setStore] = useSetting('sellpage')
  const isUpsell = sellpageSetting?.settings?.general?.upsell
  // const [reviews] = useReviews()
  const [sellpageRating] = useRatings()
  // const totalReviews = sellpageRating
  //   ? +(reviews ?? []).length + (sellpageRating?.user_ratings ?? []).length
  //   : (reviews ?? []).length
  const paygates = usePaygates()
  const paypalEx = paygates?.paypalex
  const creditCard = paygates?.creditcard
  const { fbpTrackViewContent, fbpTrackAddToCart, fbpTrackInitiateCheckout } = useFbp()
  const { pinterestTrackViewContent, pinterestTrackAddToCart } = usePinterest()
  const {
    snapchatTrackViewContent,
    snapchatTrackAddToCart,
    snapchatTrackInitiateCheckout
  } = useSnapChat()
  const {
    ttqTrackViewContent,
    ttqTrackAddToCart,
    ttqTrackInitiateCheckout,
    ttqTrackCheckout
  } = useTtq()
  const { gtagTrackViewItem, gtagTrackAddToCart, gtagTrackBeginCheckout } = useGtag()
  const redirect = useRedirect()
  const slug = router.query.subpath?.toString()
  const thisPageId = sellpageSetting?.settings?.general?.id || ''
  const images = sellpageSetting?.gallery
  const configurations = sellpageSetting?.settings?.configurations
  const socialProof = sellpageSetting?.settings?.social_proof
  const variantItems = sellpageSetting?.variants?.items ?? {}
  const properties = sellpageSetting?.variants?.properties ?? {}
  const shortDescription = sellpageSetting?.parts?.content?.short
  const [localIsPuchase] = useLocalIsPuchase()
  const [localOrder, setLocalOrder] = useLocalOrder()
  const { trackingAddToCart, trackingViewItem } = useTrackingService()
  const [, setSyncedActiveImage] = activeImageSyncedProp
  const [, setSyncedChangedVariantImage] = changedVariantImageSyncedProp
  const variantItemsData = localOrder?.items?.filter((item) => item?.page_id == thisPageId) ?? []

  const [totalItems, setTotalItems] = useState<number>(countItems(variantItemsData))
  const [quantity, , getQuantitySyncedProp] = useSyncedState<number>(1)
  const [selectedVariant, setSelectedVariant] = useState<S3Types.page_variants_item | null>(
    initVariant(sellpageSetting?.variants, initProperties(properties), variantItems)
  )
  const [isSellable, setIsSellable] = useState<boolean>(selectedVariant?.sellable ?? true)
  const [isVariantNotFound, setIsVariantNotFound] = useState<boolean>(false)
  const [isAddingToCart, setIsAddingToCart] = useState<boolean>(false)
  const [isBuyingNow, setIsBuyingNow] = useState<boolean>(false)
  const [, setSubpaths] = useCookieState('_subpath_', '')
  const mappingSize = useSizeMapping()

  const hasAvailableVariants = useMemo(
    () => Object.entries(variantItems ?? {}).some(([, variant]) => variant.sellable ?? true),
    [variantItems]
  )
  const [, setSyncedActive] = useGlobalState(isShowCartModalKey)

  const { notiDispatch } = useNotiContext()

  const { initItems, addItems, updateItems, calcOrder } = useOrderService()

  const [priceChangedVariantItems, setPriceChangedVariantItems] = usePriceChangedItems()
  const [, setSizeCollapse, getSizeCollapseSyncedProp] = useSyncedState<boolean>(true)
  const [maxNegative] = useGlobalState<{ [slug: string]: boolean }>(maxItemNegative)
  const [maxQuantityProduct] = useGlobalState<{ [slug: string]: number }>(
    maxQuantityDiscountProduct
  )
  const discountPercent = sellpageSetting?.settings?.general?.upsell_info?.discount_percent || 0
  const initiateDiscountPercentUpsell =
    sellpageSetting?.settings?.general?.upsell_info?.initiate_discount_percent || 0

  const onPropertyChanged = (prop: S3Types.item_properties, isScroll = false) => {
    const selectedProps = selectedVariant?.properties
    const newProps = { ...selectedProps, ...prop }
    getSelectedVariant(newProps, { changeImage: true, redirect: true })
    logger.logProductPage('variant')
    if (isScroll) {
      document
        ?.querySelector('.product__options')
        ?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
      setSizeCollapse(true)
    }
  }

  useEffect(() => {
    getInitSelectedVariant()

    //TODO: tracking
    if (!localIsPuchase) {
      trackingViewItem()
    }
    fbpTrackViewContent(selectedVariant?.default_price)
    pinterestTrackViewContent(selectedVariant?.default_price)
    snapchatTrackViewContent(selectedVariant?.default_price)
    ttqTrackViewContent(selectedVariant?.default_price)
    if (!router.query['utm_campaign']?.includes('+')) {
      gtagTrackViewItem(selectedVariant?.default_price)
    }
  }, [])

  useEventListener({
    type: 'visibilitychange',
    listener: onTabActive,
    options: { passive: true },
    element: typeof document !== 'undefined' ? document : undefined
  })

  useEffect(() => {
    logger.logProductPage('quantity')
  }, [quantity])

  function initVariant(
    variants?: S3Types.page_variants,
    properties?: S3Types.item_properties,
    variantItems?: page_variants_items
  ) {
    if (!variants) return null
    const queryVariant = getQuerySelectedVariant()
    if (queryVariant) {
      const selectedVariant = findVariant(queryVariant.properties, variantItems || {})
      return selectedVariant ?? Object.values(variants.items ?? {})?.[0] ?? null
    } else {
      const defaultId = variants.default_variant_id ?? ''
      if (defaultId) {
        return variants.items?.[defaultId]
      } else {
        const selectedVariant = findVariant(properties || {}, variantItems || {})
        return selectedVariant ?? Object.values(variants.items ?? {})?.[0] ?? null
      }
    }
  }

  function getInitSelectedVariant() {
    const queryVariant = getQuerySelectedVariant()
    const initSelectedVariant = queryVariant
    if (initSelectedVariant?.properties) {
      const isInitProps = compareProperties(
        initSelectedVariant.properties,
        initProperties(properties)
      )
      if (isInitProps) {
        getSelectedVariant(initSelectedVariant.properties, {
          changeImage: true,
          redirect: false
        })
      } else {
        getSelectedVariant(initSelectedVariant.properties, {
          changeImage: true,
          redirect: false
        })
      }
    } else {
      getSelectedVariant(initProperties(properties))
    }
  }

  function getQuerySelectedVariant() {
    // const variantId = router.query.variant?.toString()
    const variantBaseId = router.query.vb?.toString()
    const variantId = Object.values(variantItems).find(
      (variant) => variant?.pbase_variant_id == variantBaseId
    )?.id
    if (!variantId) {
      return null
    }

    return variantItems?.[variantId]
  }

  function getSelectedVariant(
    properties: S3Types.item_properties,
    options?: ISelectVariantOptions
  ) {
    const selectedVariant = findVariant(properties, variantItems)
    if (!selectedVariant) {
      setIsSellable(false)
      setIsVariantNotFound(true)
      // redirect('/', {
      //   query: { variant: undefined },
      //   routerOptions: { scroll: false },
      //   keepSlug: true
      // })
      redirect('/', { query: { vb: undefined }, routerOptions: { scroll: false }, keepSlug: true })
      return null
    }
    setIsVariantNotFound(false)
    setIsSellable(selectedVariant.sellable ?? true)
    setSelectedVariant(selectedVariant)
    if (options?.changeImage) {
      let index = images?.findIndex((image) => image.includes(selectedVariant.logo ?? '')) ?? 0
      index = index < 0 ? 0 : index
      setSyncedActiveImage(index)
      setSyncedChangedVariantImage(index)
    }
    if (options?.redirect && selectedVariant?.id) {
      // redirect('/', {
      //   query: { variant: selectedVariant.id },
      //   routerOptions: { scroll: false },
      //   keepSlug: true
      // })
      redirect('/', {
        query: { vb: selectedVariant.pbase_variant_id },
        routerOptions: { scroll: false },
        keepSlug: true
      })
    }
  }

  function onTabActive() {
    if (document.visibilityState === 'visible') {
      /* const localCart = new LocalCart(
        this.isPreview ? (this.$route.query.pph as string) : undefined
      ) */
      // if (JSON.stringify(orderVariants) !== JSON.stringify(LocalCart.cartVariants)) {
      //   setOrderVariants(LocalCart.cartVariants)
      // }
    }
  }

  async function onAddToCart() {
    if (!isSellable) {
      return false
    }

    getLogRocket().logRocket?.init()
    setIsAddingToCart(true)
    try {
      //TODO: tracking
      fbpTrackAddToCart(selectedVariant?.default_price, quantity)
      pinterestTrackAddToCart(selectedVariant?.default_price, quantity)
      snapchatTrackAddToCart(selectedVariant?.default_price, quantity)
      ttqTrackAddToCart(selectedVariant?.default_price, quantity)
      gtagTrackAddToCart(selectedVariant, quantity)
      if (localIsPuchase) {
        trackingViewItem()
      }
      trackingAddToCart(selectedVariant, quantity)
      logger.logProductEvent('add_to_cart')
      setTimeout(async () => {
        const existedItem = localOrder?.items?.find(
          (item) => item.variant_id === selectedVariant?.id
        )

        existedItem
          ? updateItems(
              [{ id: existedItem.id, quantity: (existedItem?.quantity ?? 0) + quantity }],
              true
            )
          : addItems([newOrderItem()])
        setIsAddingToCart(false)
        setSyncedActive(true)
      }, 500)
    } catch (error) {
      console.error('Add item error', error)
      notiDispatch({
        payload: {
          content: `Cannot add items to cart`,
          type: 'is-danger'
        },
        type: 'REMOVE_ALL_AND_ADD'
      })
      setLocalOrder(null)
      setIsAddingToCart(false)
    }
  }

  function newOrderItem(): Types.OrderItem {
    return {
      slug: router.query.subpath?.toString() || '',
      id: genSid(),
      variant_id: selectedVariant?.id,
      properties: selectedVariant?.properties,
      price: selectedVariant?.default_price,
      compare_price: selectedVariant?.compare_price,
      quantity: quantity,
      imageUrl: selectedVariant?.logo,
      title: sellpageSetting?.settings?.general?.title,
      page_id: thisPageId
    }
  }

  async function onBuyNow() {
    getLogRocket().logRocket?.init()

    if (!isSellable) {
      return notiDispatch({
        payload: {
          content: `You couldn't buy a sold out item`,
          type: 'is-warning'
        },
        type: 'REMOVE_ALL_AND_ADD'
      })
    }

    if (!creditCard && !paypalEx) {
      return notiDispatch({
        payload: {
          content: `The merchant account has been reached the limitation of payment processing`,
          type: 'is-danger'
        },
        type: 'REMOVE_ALL_AND_ADD'
      })
    }

    setIsBuyingNow(true)
    logger.logProductPage('buy')
    try {
      if (localIsPuchase) {
        trackingViewItem()
      }
      setSubpaths(router.query.subpath?.toString() || '')
      const result = await initItems([newOrderItem()], true)
      fbpTrackInitiateCheckout(result?.items, result?.amount)
      snapchatTrackInitiateCheckout(result?.items, result?.amount)
      ttqTrackInitiateCheckout(result?.items, result?.amount)
      ttqTrackCheckout(result?.items, result?.amount)
      gtagTrackBeginCheckout(result?.items, result?.amount)
      fbpTrackAddToCart(selectedVariant?.default_price, quantity)
      pinterestTrackAddToCart(selectedVariant?.default_price, quantity)
      snapchatTrackAddToCart(selectedVariant?.default_price, quantity)
      ttqTrackAddToCart(selectedVariant?.default_price, quantity)
      gtagTrackAddToCart(selectedVariant, quantity)
      trackingAddToCart(selectedVariant, quantity)

      // Analytics.track('CHECKOUT', {
      //   extra: { order, checkoutNow: true }
      // })

      redirect('/checkout')
    } catch (err: any) {
      console.error('Buy now error. Cannot create order', err)
      setIsBuyingNow(false)

      const message = 'Checkout error. Please try again later'
      const notifyContent =
        'The merchant account has been reached the limitation of payment processing'

      if (err?.response?.data?.error === message) {
        notiDispatch({
          payload: { content: notifyContent, type: 'is-danger' },
          type: 'REMOVE_ALL_AND_ADD'
        })
        // captureApiException(this, err, 'Disabled checkout', message)
      } else {
        notiDispatch({
          payload: { content: 'Checkout failure!', type: 'is-danger' },
          type: 'REMOVE_ALL_AND_ADD'
        })
        // captureApiException(this, err, 'Checkout failure')
      }
    }
  }

  /**
   * specific listener to update sellpage variant items price when price changed
   * Tickets: https://crossian.atlassian.net/wiki/spaces/tech/pages/312868959/SF+-+Price+Change
   */
  useEffect(() => {
    if (priceChangedVariantItems && !isEmpty(priceChangedVariantItems)) {
      console.warn(
        'Variant price has been changed',
        sellpageSetting?.settings.general.id,
        Object.keys(priceChangedVariantItems)
      )
      const slug = router.query.subpath?.toString()
      const path = `pages.${slug ? `${slug}.` : ''}setting.variants.items`
      const items = { ...variantItems }
      Object.entries(priceChangedVariantItems).forEach(([variant_id, item]) => {
        if (items[variant_id])
          items[variant_id] = { ...items[variant_id], default_price: item.default_price }
      })
      if (selectedVariant?.id && priceChangedVariantItems[selectedVariant.id])
        setSelectedVariant({
          ...selectedVariant,
          default_price: priceChangedVariantItems[selectedVariant.id].default_price
        })
      setStore((store: S3Types.store) => {
        const _store = immutable.set(store, path, { ...items })
        return _store
      })
      setPriceChangedVariantItems({})
    }
  }, [priceChangedVariantItems])

  const [imgWidth, setImgWidth] = useState(60)

  const getImgWidth = () => {
    if (process.browser) {
      const windowWidth = window.innerWidth
      if (windowWidth < 361) {
        setImgWidth((windowWidth - 56) / 4)
      } else if (361 < windowWidth && windowWidth < 620) {
        setImgWidth((windowWidth - 64) / 5)
      } else if (620 < windowWidth && windowWidth < 768) {
        setImgWidth((windowWidth - 96) / 8)
      }
    }
  }

  useResize(() => {
    getImgWidth()
  })
  useEffect(() => {
    getImgWidth()
  }, [])

  // UPSELL FOR BEVA

  const percentTmp = useMemo(() => {
    if (!isUpsell) return 0
    if (totalItems) {
      let tempOrders = [...(localOrder?.items?.filter((x) => x?.page_id == thisPageId) ?? [])]
      const existedItem = tempOrders?.find((item) => item.variant_id === selectedVariant?.id)

      tempOrders = existedItem
        ? [...tempOrders]?.map((order) => {
            if (order?.id === existedItem?.id) {
              return { ...order, quantity: (existedItem?.quantity ?? 0) + quantity }
            }
            return order
          })
        : [...tempOrders, { ...newOrderItem(), quantity: quantity }]

      const subTotalTmp = preCalcOrder(calcOrder(tempOrders, true))?.items_amount
      return (
        (((totalItems + quantity) * (selectedVariant?.compare_price || 0) - (subTotalTmp || 0)) *
          100) /
        ((totalItems + quantity) * (selectedVariant?.compare_price || 0))
      )
    } else {
      const quantityTmp = quantity > 1 ? quantity : 2
      const tempOrders = [{ ...newOrderItem(), quantity: quantityTmp }]
      const subTotalTmp = preCalcOrder(calcOrder(tempOrders, true))?.items_amount
      return (
        ((quantityTmp * (selectedVariant?.compare_price || 0) - (subTotalTmp || 0)) * 100) /
        (quantityTmp * (selectedVariant?.compare_price || 0))
      )
    }
  }, [selectedVariant, quantity, totalItems])

  useEffect(() => {
    if (totalItems && isUpsell) {
      let tempOrders = [...(localOrder?.items?.filter((x) => x?.page_id == thisPageId) ?? [])]
      const existedItem = tempOrders?.find((item) => item.variant_id === selectedVariant?.id)
      tempOrders = existedItem
        ? [...tempOrders]?.map((order) => {
            if (order.id === existedItem.id) {
              return { ...order, quantity: (existedItem?.quantity ?? 0) + quantity }
            }
            return order
          })
        : [...tempOrders, { ...newOrderItem(), quantity: quantity }]
      calcOrder(tempOrders, true)
    }
  }, [selectedVariant, quantity, totalItems])

  useEffect(() => {
    if (sellpageSetting) {
      const temp = [...(localOrder?.items ?? [])]
      const variantItems = sellpageSetting.variants?.items ?? {}
      const itemsTmp = temp.map((item) => ({
        ...item,
        price:
          item?.page_id == thisPageId
            ? variantItems[item.variant_id ?? '']?.default_price
            : localOrder?.variantInCart?.[item?.page_id || '']?.[item.variant_id ?? '']
                ?.default_price
      }))
      const items: SfTypes.Item[] = calcOrder(itemsTmp)
      const newOrder = preCalcOrder(items)
      setLocalOrder((prev) => ({ ...prev, ...newOrder, items }))
    }
  }, [])

  useDeepEffect(() => {
    setTotalItems(countItems(variantItemsData))
  }, [variantItemsData])

  return (
    <>
      <div className="product-form">
        <style jsx global>
          {globalStyle}
        </style>
        <div className="product-showup">
          <h1 className="product__title">{sellpageSetting?.settings?.general?.title}</h1>

          {!!sellpageRating && (
            <div className="product__rated">
              <StarRating rating={sellpageRating?.avgRated} />
              <div className="number-rating body-2">{sellpageRating?.totalRating} ratings</div>
            </div>
          )}
        </div>

        {isVariantNotFound && <div className="producackt__price">--</div>}

        {/* ---------------- This is block for conditional redering version ---------- */}
        {!isVariantNotFound &&
          version != 'bv11' &&
          version != 'bv12' &&
          version != 'bv13' &&
          version != 'bv14' && (
            <div className="product__price">
              {getCurrency(selectedVariant?.default_price ?? 0)}
              {sellpageSetting?.settings?.configurations?.show_compared_price &&
                !!selectedVariant?.default_price &&
                !!selectedVariant?.compare_price &&
                selectedVariant?.default_price < selectedVariant?.compare_price && (
                  <del className="compared">{getCurrency(selectedVariant?.compare_price ?? 0)}</del>
                )}
            </div>
          )}

        {!isVariantNotFound && (version == 'bv11' || version == 'bv13' || version == 'bv14') && (
          <div className="product__price">
            <div>
              {sellpageSetting?.settings?.configurations?.show_compared_price &&
                !!selectedVariant?.default_price &&
                !!selectedVariant?.compare_price &&
                selectedVariant?.default_price < selectedVariant?.compare_price && (
                  <del className="compared">{getCurrency(selectedVariant?.compare_price ?? 0)}</del>
                )}
              {getCurrency(selectedVariant?.default_price ?? 0)}
            </div>
            {version == 'bv11' && <div className="badge-sale">80% OFF</div>}
          </div>
        )}

        {!isVariantNotFound && version == 'bv12' && (
          <div className="product__price">
            <span className="price-red"> {getCurrency(selectedVariant?.default_price ?? 0)}</span>
            {sellpageSetting?.settings?.configurations?.show_compared_price &&
              !!selectedVariant?.default_price &&
              !!selectedVariant?.compare_price &&
              selectedVariant?.default_price < selectedVariant?.compare_price && (
                <del className="compared">{getCurrency(selectedVariant?.compare_price ?? 0)}</del>
              )}
            <span className="text-green-sale">
              (Saved{` `}
              {`${getCurrency(
                (selectedVariant?.compare_price ?? 0) - (selectedVariant?.default_price ?? 0)
              )}`}
              )
            </span>
          </div>
        )}
        {/* ---------------- End of block for conditional redering version ---------- */}

        {sellpageSetting?.settings?.configurations?.show_countdown &&
          socialProof?.countdown_time &&
          hasAvailableVariants && (
            <div className="countdown">
              <span className="countdown__title">
                <span className="countdown__title__clearance">Clearance</span>
                <span className="countdown__title__hyphen">-</span>Sale ends in
              </span>
              <Countdown time={socialProof.countdown_time} className="text-style-clock" />
            </div>
          )}

        {shortDescription && (
          <Description
            className="product__short-description rich-text"
            description={shortDescription}
          />
        )}

        {configurations?.variant_display && (
          <div
            className={`product__options ${
              isUpsell && !maxNegative?.[slug || ''] && isSellable ? 'is-upsell' : ''
            }`}
          >
            {configurations?.variant_display === 'Button'
              ? properties &&
                Object.keys(properties)?.map((property) => {
                  if (properties?.[property].values) {
                    return (
                      <RadioButton
                        classVariant="is-variant"
                        widthImg={imgWidth}
                        key={`radio_${property}`}
                        propertyName={property}
                        selectedProperty={selectedVariant?.properties?.[property] ?? ''}
                        isShowImage={(sellpageSetting?.variants?.type_show || '') === property}
                        values={properties?.[property].values || {}}
                        onPropertyChanged={(propertyCode) => {
                          onPropertyChanged({ [property]: propertyCode })
                        }}
                      />
                    )
                  } else return null
                })
              : Object.keys(properties).map((property) => {
                  if (properties?.[property].values) {
                    return (
                      <DropdownSelect
                        key={`dropdown_${property}`}
                        propertyName={property}
                        selectedProperty={selectedVariant?.properties?.[property] ?? ''}
                        values={Object.keys(properties?.[property].values ?? {})}
                        onPropertyChanged={(propertyCode) => {
                          onPropertyChanged({ [property]: propertyCode })
                        }}
                      />
                    )
                  } else return null
                })}
          </div>
        )}

        {mounted && (
          <SizeCalculator
            activeSyncedCollapseProp={getSizeCollapseSyncedProp()}
            onPropertyChanged={(property) => onPropertyChanged(property, true)}
          />
        )}
        {isUpsell && !maxNegative?.[thisPageId || ''] && isSellable && mounted && (
          <div
            className={`product-upsells ${mappingSize && mappingSize.size_chart ? '' : 'no-size'}`}
          >
            <>
              {totalItems === 0 ? (
                <>
                  <span className="product-upsells__bold">
                    ADD {quantity > 1 ? quantity : 2} ITEMS TO CART TO GET {~~(percentTmp || 0)}%
                    OFF{' '}
                  </span>
                </>
              ) : (
                <>
                  <span className="product-upsells__bold">
                    EXTRA{' '}
                    {discountPercent * maxQuantityProduct?.[thisPageId || ''] +
                      initiateDiscountPercentUpsell || 0}
                    % OFF FOR {quantity > 1 ? `${quantity} ITEMS MORE` : 'NEXT ITEM'} IN CART
                  </span>
                </>
              )}
            </>
            <span>
              Apply to any{' '}
              {Object.keys(properties)
                ?.map((prop) => [prop[0]?.toLocaleUpperCase(), ...prop?.slice(1)]?.join(''))
                ?.join(' and ')}
            </span>
          </div>
        )}
        <div className="cta-buttons field is-grouped is-grouped-multiline">
          {hasAvailableVariants && (
            <div className="control">
              <QuantityInput
                className="is-fullwidth-mobile"
                label={isUpsell ? '' : 'Quantity'}
                valueSyncedProp={getQuantitySyncedProp()}
                isHorizontal={false}
              />
            </div>
          )}

          <div className={`control ${isSellable ? 'btn-width' : 'btn-sold-out'}`}>
            <button
              className={[
                'add-to-cart-button button--cta button is-primary',
                ...(isAddingToCart ? ['is-loading'] : [])
              ].join(' ')}
              disabled={!isSellable}
              onClick={() => onAddToCart()}
            >
              <img src={iconPath('cart.svg')} alt="" />
              <span className="button-content">{isSellable ? 'Add to cart' : 'Sold out'}</span>
            </button>
          </div>
          <div style={{ display: isSellable ? 'block' : 'none' }} className="control btn-width">
            <button
              className={[
                'buy-now-button button--cta button is-white',
                ...(isBuyingNow ? ['is-loading'] : [])
              ].join(' ')}
              disabled={!isSellable}
              onClick={() => onBuyNow()}
            >
              <img src={iconPathBvf('atm_white.svg')} alt="atm card" className="icon-white" />
              <img src={iconPathBvf('card.svg')} alt="card" className="icon-black" />
              <span className="button-content">Buy it now</span>
            </button>
          </div>
        </div>

        {sellpageSetting?.settings?.configurations?.show_social_proof && hasAvailableVariants && (
          <div className="visting-people">
            <strong>
              <span className="hight-light">Limited stock!</span>{' '}
              <RandomNumber initValue={socialProof?.visiting_people ?? 0} />
            </strong>{' '}
            people are viewing this and{' '}
            <strong>
              <RandomNumber initValue={socialProof?.purchased_orders ?? 0} type="up" />
            </strong>{' '}
            purchased it.
          </div>
        )}

        {version == 'bv14' ? (
          <>
            <div className="bv14-shipping-return">
              <div className="bv14-shipping-return--box">
                <div className="bv14-shipping-return--box__icon">
                  <img src={iconPath('shipping_brown.svg')} alt="shipping" />
                </div>
                <div className="bv14-shipping-return--box__title">Standard shipping</div>
                <div className="bv14-shipping-return--box__description">7 - 10 business days</div>
              </div>

              <div className="bv14-shipping-return--box">
                <div className="bv14-shipping-return--box__icon">
                  <img src={iconPath('return_brown.svg')} alt="return" />
                </div>
                <div className="bv14-shipping-return--box__title">Free exchange</div>
                <div className="bv14-shipping-return--box__description">within 45 days</div>
              </div>
            </div>

            <div className="bv14-shipping-return1">
              <div className="bv14-shipping-return1--box">
                <div className="bv14-shipping-return1--box__icon">
                  <img src={iconPath('return_black_around.svg')} alt="return" />
                </div>
                <div className="bv14-shipping-return1--box__title">Free exchange</div>
                <div className="bv14-shipping-return1--box__description">within 45 days</div>
              </div>

              <div className="bv14-shipping-return1--box">
                <div className="bv14-shipping-return1--box__icon">
                  <img src={iconPath('shipping_black_around.svg')} alt="shipping" />
                </div>
                <div className="bv14-shipping-return1--box__title">Standard shipping</div>
                <div className="bv14-shipping-return1--box__description">7 - 10 business days</div>
              </div>
            </div>

            {/* this line will be deleted when PM chooses one of among versions */}
            <div className="bv14-shipping-return2" style={{ marginBottom: 56 }}>
              <div className="bv14-shipping-return2--box">
                <img
                  className="bv14-shipping-return2--box__icon"
                  src={iconPath('return_green.svg')}
                  alt="return"
                />
                <div className="bv14-shipping-return2--box__title">
                  Free exchange within 45 days
                </div>
              </div>

              <div className="bv14-shipping-return2--box">
                <img
                  className="bv14-shipping-return2--box__icon"
                  src={iconPath('shipping_black_gray_around.svg')}
                  alt="shipping"
                />
                <div className="bv14-shipping-return2--box__description">
                  Arrives within 7-10 business days
                </div>
              </div>
            </div>

            <div className="bv14-shipping-return2">
              <div className="bv14-shipping-return2--box">
                <img
                  className="bv14-shipping-return2--box__icon"
                  src={iconPath('return_green_around.svg')}
                  alt="return"
                  /* this line will be deleted when PM chooses one of among versions */
                  style={{ marginRight: 18 }}
                />
                <div className="bv14-shipping-return2--box__title">
                  Free exchange within 45 days
                </div>
              </div>

              <div className="bv14-shipping-return2--box">
                <img
                  className="bv14-shipping-return2--box__icon"
                  src={iconPath('shipping_black_light.svg')}
                  alt="shipping"
                  /* this line will be deleted when PM chooses one of among versions */
                  style={{ marginRight: 18 }}
                />
                <div className="bv14-shipping-return2--box__description">
                  Arrives within 7-10 business days
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="shipping-return--box">
            <div className="shipping-return--box--title">
              <img
                className="shipping-return--box--icon"
                src={iconPathBvf('shield-check.svg')}
                alt=""
              />
              <div>45-Days Satisfaction Guarantee</div>
            </div>

            <div className="shipping-return--box--description">
              Easy returns, exchanges, and refunds. Simply put, your satisfaction is our
              satisfaction!
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const globalStyle = css.global`
  .product-form {
    color: var(--gray-8);
    .product-showup {
      display: flex;
      flex-direction: column-reverse;
    }
    .product {
      &__rated {
        display: flex;
        align-items: center;
        font-size: 16.5px;
        padding-bottom: 0px;
        .star-rating {
          height: 22px;
        }
        .number-rating {
          margin-left: 12px;
        }
      }

      &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        padding-bottom: 8px;
      }

      &__price {
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        padding: 8px 0px;
        .compared {
          margin-left: 6px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;

          color: var(--gray-7);
        }
      }

      &__short-description {
        margin-bottom: 24px;
      }

      &__options {
        margin-bottom: 18px;
        .radio-buttons {
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }

      @media screen and (max-width: 768px) {
        &__title {
          padding-bottom: 0px;
        }

        &__rated {
          padding-bottom: 8px;
        }

        &__options {
          &.is-upsell {
            margin-bottom: 0px;
          }
          .radio-buttons {
            margin-bottom: 18px;
          }
        }
      }
    }

    .countdown {
      margin-bottom: 24px;
      background-color: var(--primary-10);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      padding: 7px 0px;
      gap: 3px;

      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      &__title {
        display: flex;
        &__clearance {
          color: var(--red-600);
          font-weight: 700;
        }
        &__hyphen {
          margin: 0 3px;
        }
      }
      color: var(--primary-100);
      &__clock {
        width: 20px;
        height: 20px;
      }

      .text-style-clock {
        font-weight: 600;
        color: var(--red-600);
        margin: 0 0;
        padding: 0 0;
      }
      @media screen and (max-width: 768px) {
        & {
          margin-bottom: 20px;
        }
      }
    }

    .product-upsells {
      background: #e8f5e9;
      border: 1px dashed #1d9f54;
      border-radius: 4px;
      padding: 8px 6px;

      display: flex;
      flex-direction: column;
      align-items: center;

      margin-top: 14px;
      margin-bottom: 16px;

      &.no-size {
        margin-top: 20px;
      }

      font-weight: 500;
      font-size: 16px;
      text-align: center;
      color: #1d9f54;
      line-height: 24px;

      &__bold {
        font-weight: 700;
        font-size: 15px;
      }

      @media screen and (min-width: 768px) {
        & {
          margin-bottom: 12px;
        }

        &.no-size {
          margin-top: 32px;
        }
      }
    }

    .cta-buttons {
      margin-bottom: -4px;
      &.field.is-grouped {
        & > .control {
          margin-bottom: 8px;
          &:not(:last-child) {
            margin-right: 12px;
          }

          & > .button {
            border-radius: 4px;
            gap: 10px;

            .button-content {
              font-weight: 600;
              font-size: 16px;
              line-height: 28px;
            }
            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }

      .add-to-cart-button {
        padding: 14px 18px;
        border: none;
      }

      .buy-now-button {
        padding: 12px 18px;

        .icon-white {
          display: none;
        }

        .icon-black {
          display: block;
        }

        &:hover,
        &.is-hovered,
        &:focus,
        &.is-focused,
        &:active,
        &.is-active {
          .icon-white {
            display: block;
          }
          .icon-black {
            display: none;
          }
        }
      }

      .quantity-input {
        .button {
          width: 55px;
        }
      }

      @media screen and (max-width: 992px) {
        & > .control {
          width: 100%;

          & > .button {
            width: 100%;
          }
        }
      }
    }

    .visting-people {
      font-size: 13px;
      line-height: 24px;
      margin-bottom: 16px;
      .hight-light {
        color: var(--red-600);
      }
    }

    .shipping-return {
      &--box {
        padding: 16px;
        background: var(--primary-10);
        border: none;
        border-radius: 8px;

        &--icon {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }

        &--title {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: var(--gray-9);

          display: flex;
          margin-bottom: 8px;
        }

        &--description {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #363636;
          strong {
            font-weight: 600;
          }
        }
      }
    }
    .bv14-shipping-return {
      padding: 16px 8px;
      background-color: var(--primary-10);
      border-radius: 8px;
      display: flex;
      justify-content: space-around;

      /* this line will be deleted when PM chooses one of among versions */
      margin-bottom: 24px;

      &--box {
        text-align: center;
        &__icon {
          margin-bottom: 4px;
        }
        &__title {
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: var(--gray-9);
        }
        &__description {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--gray-9);
        }
      }

      @media screen and (min-width: 330px) {
        padding: 16px;

        &--box {
          width: 50%;
        }
      }
    }

    .bv14-shipping-return1 {
      padding: 16px 8px;
      border-radius: 8px;
      display: flex;
      justify-content: space-around;

      /* this line will be deleted when PM chooses one of among versions */
      margin-bottom: 40px;

      &--box {
        text-align: center;
        &__icon {
          margin-bottom: 4px;
        }
        &__title {
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: var(--gray-9);
        }
        &__description {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--gray-9);
        }
      }

      @media screen and (min-width: 350px) {
        padding: 16px;

        &--box {
          width: 50%;
        }
      }
    }

    .bv14-shipping-return2 {
      &--box {
        display: flex;
        align-items: center;
        &:first-child {
          margin-bottom: 6px;
        }

        &__icon {
          margin-right: 12px;
        }
        &__title {
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: #1d9f54;
        }
        &__description {
          font-weight: 400;
          font-size: 15px;
          line-height: 24px;
          color: var(--gray-8);
        }
      }
    }

    @media screen and (min-width: 768px) {
      .product {
        &__rated {
          .star-rating {
            height: 24px;
          }
        }
        &__title {
          font-size: 24px;
          line-height: 36px;
        }
        &__options {
          margin-bottom: 8px;

          .radio-buttons {
            margin-bottom: 24px;
          }
        }
      }
      .cta-buttons {
        .quantity-input {
          .control-input {
            width: 100%;
          }
          .input {
            width: 100%;
          }
        }
        .btn-width {
          width: calc((100% - 12px) * 0.5);
          .button--cta {
            width: 100%;
          }
        }
      }
      .visting-people {
        margin-bottom: 24px;
      }

      .product-showup {
        flex-direction: column;
      }
    }
    @media screen and (min-width: 992px) {
      .cta-buttons {
        .quantity-input {
          .button {
            width: 48px;
          }
          .control-input {
            width: auto;
          }
          .input {
            width: 60px;
          }
        }
        .quantity-button {
          width: auto;
        }
        .btn-width {
          width: calc((100% - 180px) * 0.5);
          .button--cta {
            width: 100%;
          }
        }
        .btn-sold-out {
          width: calc((100% - 180px) * 0.5);
          .button--cta {
            width: 100%;
          }
        }
      }
    }
  }

  .version-enhancement {
    .product-form {
      .product__rated {
        padding-bottom: 4px;
      }
      .product__title {
        padding-bottom: 0px;
      }
      .product__price {
        padding-top: 10px;
      }
    }
  }
  .bv11,
  .bv13,
  .bv14 {
    .product__price {
      display: flex;
      align-items: center;
      .compared {
        margin: 0px 6px 0px;
      }
      .badge-sale {
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        color: #ffffff;

        padding: 2px 10px;
        background-color: var(--red-600);
        border-radius: 4px;

        margin-left: 8px;
        height: 28px;
      }
    }
  }
  .bv12 {
    .product__price {
      .text-green-sale {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;

        color: #1d9f54;
        margin-left: 8px;
      }
      .price-red {
        color: var(--red-600);
      }
    }
  }
`
