import { ChangeEvent, ReactNode } from 'react'

import { FileDrop } from 'react-file-drop'
import css from 'styled-jsx/css'
import { iconPath } from '@ui/helpers'
import { removeSpecials } from '@ui/helpers/utilities'
import { useMounted } from '@ui/hooks'

interface FileUploaderProps {
  label?: string
  width?: string
  height?: string
  multiple?: boolean
  enableUploadSameFileTwice?: boolean
  placeholder?: string
  placeholderIcon?: ReactNode
  onFilesChanged?: (files: File[]) => void
  acceptFileType?: string
  showIcon?: boolean
  disabled?: boolean
  className?: string
}

export function FileUploader({
  enableUploadSameFileTwice = true,
  acceptFileType = 'image/*,.pdf,.doc,.docx,.xls,.xlsx',
  ...props
}: FileUploaderProps) {
  const isMounted = useMounted()

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    !props.disabled && props?.onFilesChanged?.(getFileList(event.target.files))
  }

  const onDrop = (files: FileList | null) => {
    !props.disabled && props?.onFilesChanged?.(getFileList(files))
  }

  const onClick = (event: any) => {
    enableUploadSameFileTwice && (event.target.value = null)
  }

  return (
    <div
      className={`file-uploader ${props.className ?? ''} ${props.disabled ? 'disabled' : ''}`}
      style={{
        ...(props.width ? { width: props.width } : {}),
        ...(props.height ? { height: props.height } : {})
      }}
    >
      {isMounted && (
        <FileDrop onDrop={onDrop}>
          <label>
            <span className="w-100 text-center body-2 pt-3 d-flex flex-column align-items-center">
              {props.showIcon && (
                <figure className="image is160x160 pb-4">
                  <img src={iconPath('image_gray.svg')} alt="upload icon" />
                </figure>
              )}
            </span>
            <span className="text-upload">Upload image</span>
            <input
              type="file"
              onChange={onChange}
              onClick={onClick}
              multiple={props.multiple}
              accept={acceptFileType}
            />
          </label>
        </FileDrop>
      )}
      <style jsx>{style}</style>
    </div>
  )
}

export const getFileList = (files: FileList | null): File[] => {
  if (!files || files.length === 0) return []
  const fileList: File[] = []
  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    const newFile = new File([file || ''], removeSpecials(file?.name || ''))
    if (newFile) fileList.push(newFile)
  }
  return fileList
}

const style = css`
  .file-uploader {
    width: 100%;
    height: 96px;
    display: flex;
    flex-direction: column;

    .text-upload {
      font-weight: 600;
      font-size: 13px;
      line-height: 24px;
      margin-left: 12px;
      color: #939eab;
    }

    :global(.file-drop) {
      width: 100%;
      height: 100%;

      :global(.file-drop-target) {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        cursor: pointer;
      }

      label {
        cursor: pointer;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        font-size: 13px;
      }

      :global(.icon) {
        fill: #c5cdd5;
        width: 100%;
        transition: 0.2s ease;
      }

      input[type='file'] {
        display: none;
      }
    }

    &.disabled {
      opacity: 0.7;
      :global(.file-drop .file-drop-target) {
        pointer-events: none;
      }
    }
  }
`
