import { FC, useEffect, useRef, useState } from 'react'
import { freeze, iconPathBvf, sleep, unFreeze } from '@ui/helpers'
import css from 'styled-jsx/css'
import {
  SyncedProp,
  useDeepEffect,
  usePropertiesMapping,
  useSetting,
  useSizeMapping
} from '@ui/hooks'
import { InputGroup } from '../shared/Input/InputGroup'
import { validateAddressField } from '@ui/validators'
import { findVariant } from '../product/ProductForm'
import { CartBvfService, SizeCalculateCreateRequest } from '@libs/client/order/services'
import { useNotiContext } from '../shared/Notification'
import { isSizeCalculatorKey, useGlobalState, useLocalShippingAddress } from '@libs/client'
import { logger } from '@ui/analytics'

interface ISizeChart {
  activeSyncedProp: SyncedProp<boolean>
  onPropertyChanged: (prop: S3Types.item_properties) => void
}
interface ISizeForm {
  underbust?: string
  cupsize?: string
}
const NOT_SIZE = 'not-size'

const SizeModal: FC<ISizeChart> = ({ activeSyncedProp, onPropertyChanged }) => {
  const [syncedActive, setSyncedActive] = activeSyncedProp
  const [sizeForm] = useGlobalState<ISizeForm | null>(isSizeCalculatorKey)
  const [searching, setSearching] = useState(false)
  const [isSendEmail, setIsSendEmail] = useState(false)
  const [localshipping, setLocalShipping] = useLocalShippingAddress()
  const [email, setEmail] = useState(localshipping?.email || '')
  const [emailError, setEmailError] = useState('')
  const [result, setResult] = useState<string>('')
  const sizeOption = useSizeMapping()
  const mappingProperties = usePropertiesMapping()
  const [sellpageSetting] = useSetting('sellpage')
  const { notiDispatch } = useNotiContext()
  const ref = useRef(null)
  const toggleModal = () => {
    setSyncedActive(!syncedActive)
    logger.logProductEvent('close_size_calculator')
  }

  useDeepEffect(() => {
    if (
      sizeOption &&
      sizeForm &&
      Object.values(sizeForm || {}).every((x) => !!x) &&
      Object.values(sizeForm || {}).length > 1
    ) {
      handleSearchSize()
    }
  }, [sizeOption])

  const handleSearchSize = async () => {
    setSearching(true)
    await sleep(500).then(() => setSearching(false))
    const ceilUnderbust = Math.ceil(+(sizeForm?.underbust || ''))
    const res = sizeOption.sizes?.[ceilUnderbust]?.[sizeForm?.cupsize || '']
    if (res) {
      const variant = findVariant(
        { [sizeOption?.mapping_option || '']: res },
        sellpageSetting?.variants?.items ?? {},
        true
      )
      const property =
        mappingProperties?.[sizeOption?.mapping_option || ''] || sizeOption?.mapping_option || ''
      setResult(variant?.properties?.[property] || NOT_SIZE)
      scrollToBottom()
    } else {
      const element = document.getElementById('on-input')
      element?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      setResult(NOT_SIZE)
    }
  }
  const handleSelectSize = () => {
    onPropertyChanged({ [sizeOption?.mapping_option || '']: result })
    notiDispatch({
      payload: {
        content: `Size ${result} has been selected`,
        type: 'is-success',
        timeout: 3
      },
      type: 'REMOVE_ALL_AND_ADD'
    })
    setSyncedActive(false)
  }

  useDeepEffect(
    ([currEmail]: [string], [prevEmail]: [string]) => {
      if (currEmail !== prevEmail) {
        validateAddressField('email', email)
          .then(() => {
            setEmailError('')
          })
          .catch((err) => {
            setEmailError(err.details?.[0]?.message)
          })
      }
    },
    [{ email }]
  )

  const scrollToBottom = () => {
    if (ref.current) {
      setTimeout(() => {
        ;(ref.current as any).scrollTo({
          top: (ref.current as any).scrollHeight,
          behavior: 'smooth'
        })
      }, 100)
    }
  }

  const handleSendEmail = async () => {
    try {
      await validateAddressField('email', email)
      setIsSendEmail(true)
      const body: SizeCalculateCreateRequest = {
        email,
        underbust: Number(sizeForm?.underbust),
        cup_size: sizeForm?.cupsize,
        page_id: sellpageSetting?.settings.general.id
      }
      setLocalShipping({ ...localshipping, email })
      await CartBvfService.sizeCalculate({ body })
      notiDispatch({
        payload: {
          content: 'Thank you! We will let you know when your size is available',
          type: 'is-success',
          timeout: 3
        },
        type: 'REMOVE_ALL_AND_ADD'
      })
      setSyncedActive(false)
    } catch (error: any) {
      if (error.details?.[0]?.message) {
        setEmailError(error.details?.[0]?.message)
      } else {
        console.error('Error when send Email Size calculator', error)
      }
    } finally {
      setIsSendEmail(false)
    }
  }

  useEffect(() => {
    if (syncedActive) {
      freeze()
    } else {
      unFreeze()
    }
  }, [syncedActive])

  return (
    <>
      <style jsx>{globalStyle}</style>
      <div className="size-modal ">
        <div
          className={`modal slide-right${syncedActive ? ' is-active' : ''}`}
          style={{
            visibility: syncedActive ? 'visible' : 'hidden'
          }}
        >
          <div className="modal-background" onClick={() => toggleModal()}></div>
          <div className="modal-card animated faster">
            <header className="modal-card-head">
              <p className="modal-card-title">Size Calculator</p>
              <button className="delete" aria-label="close" onClick={() => toggleModal()} />
            </header>
            <section className="modal-card-body" ref={ref}>
              <div className="cup-size">
                <img className="cup-size__img" src={iconPathBvf('cup-size.svg')} alt="cup size" />
                <span className="cup-size__title">
                  To measure, wrap the tape around the fullest part of your underbust. Enter your
                  underbust measurements & select your cup size
                </span>
              </div>
              <SizeForm
                cupSizeOptions={sizeOption.cup_size_options || []}
                isSearching={searching}
                onSearch={handleSearchSize}
              />
              {!!result && <hr />}
              {!!result && result !== NOT_SIZE && (
                <>
                  <div className="size-result">
                    <div className="size-result__title">Your ideal size is:</div>
                    <div className="size-result__variant">{result}</div>
                  </div>
                  <button
                    className="select-size-button button--cta button is-primary"
                    onClick={handleSelectSize}
                  >
                    <span className="button-content">Select this size</span>
                  </button>
                </>
              )}
              {result == NOT_SIZE && (
                <>
                  <div className="size-warning has-bg">
                    Unfortunately, we {`don't`} carry your bra size at this time.
                  </div>
                  <div className="size-warning">
                    Please leave your contact here and we will let you know when your size is
                    available!
                  </div>
                  <input
                    value={email ?? ''}
                    name="email"
                    className={`input ${emailError ? 'is-danger' : ''}`}
                    type="email"
                    placeholder="Email"
                    onChange={(event) => setEmail(event.target.value.trim())}
                  />
                  <p className="help is-danger">{emailError}</p>
                  <button
                    className={`select-size-button button--cta button is-primary mt-3 ${
                      isSendEmail ? 'is-loading' : ''
                    }`}
                    onClick={handleSendEmail}
                  >
                    <span className="button-content">Submit</span>
                  </button>
                  <div className="btn-back" onClick={() => setSyncedActive(false)}>
                    Back
                  </div>
                </>
              )}
            </section>
          </div>
        </div>
      </div>
    </>
  )
}
const SizeForm = ({
  onSearch,
  isSearching,
  cupSizeOptions
}: {
  onSearch: () => void
  isSearching: boolean
  cupSizeOptions: string[]
}) => {
  const [sizeForm, setSizeForm] = useGlobalState<ISizeForm | null>(isSizeCalculatorKey)
  // const [syncedSizeForm, setSyncedSizeForm] = sizeSyncedForm
  const [error, setError] = useState(false)
  useDeepEffect(() => {
    if (sizeForm && Object.values(sizeForm || {}).length > 1) {
      const isValid = Object.values(sizeForm || {}).every((x) => !!x)
      setError(!isValid)
    } else {
      setError(true)
    }
  }, [sizeForm])
  const onSizeFieldChange = (event: string, field: keyof ISizeForm) => {
    if (field === 'cupsize') {
      setSizeForm({
        ...(sizeForm || {}),
        [field]: event
      })
    } else {
      const x = event ? event.match(/^\d*\.?\d{0,1}$/) : event
      if (x || x == '') {
        setSizeForm({
          ...(sizeForm || {}),
          [field]: event
        })
      }
    }
  }

  return (
    <div className="size-form">
      <style jsx>{sizeStyle}</style>
      <div className="columns">
        <div className="column is-5 is-5-mobile" id="on-input">
          <div className="size-form__item--quantity">1</div>
          <span className="size-form__item--label">Underbust</span>
        </div>
        <div className="column is-7 is-7-mobile">
          <InputGroup className="size-form__group" errClassName={`size-underbust_err`} suffix="in">
            <input
              className="p-inputtext p-component body-2"
              pattern="/^\d*\.?\d{0,1}$/"
              placeholder="Enter here"
              type="number"
              inputMode="decimal"
              value={sizeForm?.underbust || ''}
              onChange={(e) => onSizeFieldChange(e.target.value, 'underbust')}
            />
          </InputGroup>
        </div>
      </div>
      <div className="columns">
        <div className="column is-5 is-5-mobile pt-0">
          <div className="size-form__item--quantity">2</div>
          <span className="size-form__item--label">Cup size</span>
        </div>
        <div className="column is-7 is-7-mobile pt-0">
          <div className="select">
            <select
              value={sizeForm?.cupsize || ''}
              className={`${sizeForm?.cupsize ? '' : 'placeholder'}`}
              onChange={(event) => onSizeFieldChange(event.target.value, 'cupsize')}
              onBlur={(event) => onSizeFieldChange(event.target.value, 'cupsize')}
            >
              <option value={''} disabled={true} hidden={true}>
                Select cup size
              </option>
              {(cupSizeOptions || []).map((state: string) => (
                <option key={`size_${state}`} value={state}>
                  {state}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <button
        className={['search-size button--cta button', ...(isSearching ? ['is-loading'] : [])].join(
          ' '
        )}
        disabled={isSearching || error}
        onClick={() => {
          onSearch()
          logger.logProductEvent('find_my_size')
        }}
      >
        <span className="button-content">Find my size</span>
      </button>
    </div>
  )
}

export default SizeModal

export const sizeStyle = css.global`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .size-form {
    width: 100%;
    padding-top: 18px;
    .columns {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .column {
        display: flex;
        align-items: center;
      }
    }
    .pt-0 {
      padding-top: 0px;
    }

    &__item {
      &--quantity {
        border-radius: 50%;
        background: var(--primary-10);
        padding: 2px 13px;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: var(--primary-100);
        margin-right: 8px;
      }
      &--label {
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: var(--gray-8);
      }
    }
    .is-full {
      width: 100%;
    }
    &__group {
      border: 1px solid var(--indigo-6) !important;
      box-shadow: none !important;
      background: #fff !important;
      width: 100%;
      .suffix {
        padding: 12px 16px !important;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: var(--gray-9);
      }
      .delimiter {
        border-left: 1px solid var(--indigo-6);
      }
      .p-inputtext {
        border: none;
        border-radius: 4px;
        background: #fff;
        height: auto;
        padding: 12px 16px;
        color: var(--gray-8);
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;

        &::placeholder {
          color: #b5b5b5;
        }
      }
    }

    .search-size {
      width: 100%;
      padding: 10px;
      margin: 8px 0 16px;
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      border: 2px solid var(--gray-8);
    }

    .select {
      display: block;
      height: auto;
      width: 100%;
      select {
        height: auto;
        padding: 12px 40px 12px 16px;
        width: 100%;
        white-space: nowrap;
        border: 1px solid var(--indigo-6);
        &.placeholder {
          color: #b5b5b5;
        }
        &:focus {
          box-shadow: none;
          border: 1px solid var(--indigo-6);
        }
      }

      &::after {
        border-color: var(--gray-8) !important;
        border-bottom-width: 2px !important;
        border-left-width: 2px !important;
      }
    }
    @media screen and (min-width: 768px) {
      padding-top: 32px;
      &__item {
        &--quantity {
          margin-right: 16px;
        }
      }
      .search-size {
        margin-top: 0;
      }
    }
  }
`
export const globalStyle = css.global`
  .size-modal {
    $padding: 30px;
    $padding-mobile: 18px;
    hr {
      padding: 0px;
      margin: 0px 0px 16px;
    }
    .modal {
      z-index: 1000;

      .modal-card {
        height: 100vh;
        max-height: 100vh;
        max-width: 555px;
        margin-right: 0;

        @media screen and (max-width: 768px) {
          margin-left: 0;
          max-width: 100vw;
          width: 100vw;
        }

        .modal-card-head {
          border-radius: 0;
          background: var(--primary-10);
          border: none;
          font-weight: 600;
          font-size: 24px;
          line-height: 40px;
          color: var(--gray-8);
          padding: 16px 24px 16px 56px;

          .modal-card-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
          }

          .delete {
            font-size: 48px;
            height: 48px;
            max-height: 48px;
            max-width: 48px;
            min-height: 48px;
            min-width: 48px;
            width: 48px;
          }

          @media screen and (max-width: 768px) {
            padding: 6px 20px 6px 16px;
            font-size: 18px;
            line-height: 28px;
            .delete {
              font-size: 40px;
              height: 40px;
              max-height: 40px;
              max-width: 40px;
              min-height: 40px;
              min-width: 40px;
              width: 40px;
            }
          }
        }

        .modal-card-body {
          /* padding: 0; */
          position: relative;
          padding: 16px 12px 52px;
          &.disabled {
            pointer-events: none;
          }

          .cup-size {
            display: flex;
            flex-direction: column;
            &__img {
              width: 100%;
              height: auto;
              padding-bottom: 12px;
            }
            &__title {
              font-weight: 400;
              font-size: 15px;
              line-height: 24px;

              @media screen and (min-width: 768px) {
                font-size: 16px;
              }
            }
          }
        }

        .label__icon {
          display: inline-block;

          &:not(:last-child) {
            margin-left: 10px;
          }

          img {
            width: unset;
            height: 30px;
          }
        }
      }
      .size-result {
        padding: 15px 12px;
        background: var(--primary-10);
        color: var(--gray-8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
        &__title {
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          padding-bottom: 8px;
        }
        &__variant {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
        }
      }
      .size-warning {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 16px;
        border-radius: 4px;
        &.has-bg {
          background: var(--primary-10);
          padding: 12px 16px;
          font-weight: 600;
          font-size: 18px;
          margin-bottom: 24px;
          line-height: 28px;
        }
      }
      .select-size-button {
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: var(--white);
        padding: 14px 0;
        width: 100%;
        text-align: center;
      }

      .btn-back {
        cursor: pointer;
        min-width: 100px;
        text-align: center;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        color: var(--gray-8);
        padding-top: 24px;
      }

      .mt-3 {
        margin-top: 24px;
      }
    }

    @media screen and (min-width: 768px) {
      .modal .modal-card {
        .modal-card-body {
          padding: 40px 56px;
          .cup-size {
            display: flex;
            flex-direction: column;
            &__img {
              max-width: 350px;
              padding-bottom: 22px;
              margin: auto;
            }
          }
        }
        .modal-card-head .modal-card-title {
          font-weight: 600;
          font-size: 24px;
          line-height: 40px;
        }
      }
      hr {
        margin: 8px 0px 24px;
      }
    }
  }
`
