import { PropsWithChildren, ReactNode, useEffect, useRef } from 'react'
import { ValidatorFn } from '../../../hooks/useForm'
import css from 'styled-jsx/css'

export interface IInputGroup {
  prefix?: string | ReactNode
  suffix?: string | ReactNode
  prefixDelimiter?: boolean
  suffixDelimiter?: boolean
  className?: string
  errClassName?: string
  error?: string
  title?: string | ReactNode
  require?: boolean
  validators?: ValidatorFn[]
}

export function InputGroup({
  prefix,
  suffix,
  prefixDelimiter = !!prefix,
  suffixDelimiter = !!suffix,
  className,
  error,
  title,
  children,
  errClassName
}: PropsWithChildren<IInputGroup>) {
  const groupRef = useRef(null)
  const onFocus = (e: any) => {
    const classList = (groupRef.current as any)?.classList
    e.type == 'focusin' ? classList?.add('focus') : classList?.remove('focus')
  }
  useEffect(() => {
    const group: any = groupRef.current
    group.addEventListener('focusin', onFocus)
    group.addEventListener('focusout', onFocus)
    return () => {
      group.removeEventListener('focusin', onFocus)
      group.addEventListener('focusout', onFocus)
    }
  }, [groupRef])
  return (
    <>
      {title && <div className="title mb-1 body-2b"> {title} </div>}
      <div
        className={`p-inputgroup ${className || ''} ${error ? 'error-control' : ''}`}
        ref={groupRef}
      >
        {prefix && (
          <>
            <div className="prefix">{prefix}</div>
            {prefixDelimiter && <div className="delimiter delimiter--prefix">&#8203;</div>}
          </>
        )}
        <div className="chilren-container">{children}</div>
        {suffix && (
          <>
            {suffixDelimiter && <div className="delimiter delimiter--suffix">&#8203;</div>}
            <div className="suffix">{suffix}</div>
          </>
        )}
      </div>
      {error && <div className={`error-message ${errClassName}`}> {error} </div>}
      <style jsx>{style}</style>
    </>
  )
}

const style = css`
  .p-inputgroup {
    border-radius: 4px;
    border: 1px solid #eaecf0;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 0.8px 1.6px rgba(101, 115, 129, 0.13);
    transition: border 0.2s ease, box-shadow 0.2s ease;
    display: -webkit-flex;

    &.error-control {
      &,
      &:hover,
      &.focus {
        box-shadow: none;
        border: 1px solid #fff !important;
      }
    }

    :global(input:focus) {
      outline: 0;
    }
  }

  .chilren-container {
    flex: 1;
    width: 100%;
    :global(.p-dropdown),
    :global(.p-multiselect),
    :global(.p-inputnumber),
    :global(.p-inputmask),
    :global(.p-inputtext),
    :global(.p-autocomplete) {
      width: 100%;
    }
  }

  .delimiter {
    border-left: 1px solid #e0e3e9;
    height: calc(100% - 16px);
    z-index: 1;
    &--prefix {
      margin-right: -1px;
    }
    &--suffix {
      margin-left: -1px;
    }
  }
  .prefix,
  .suffix {
    padding: 8px;
    display: inline-flex;
    user-select: none;
  }

  .error-message {
    color: var(--secondary);
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
  }
`
