import { logger } from '@ui/analytics'
import { iconPathBvf } from '@ui/helpers'
import { SyncedProp, useSizeMapping, useSyncedState } from '@ui/hooks'
import { FC } from 'react'
import css from 'styled-jsx/css'
import SizeModal from './SizeModal'

interface ISizeCalculator {
  activeSyncedCollapseProp: SyncedProp<boolean>
  onPropertyChanged: (prop: S3Types.item_properties) => void
}
const SizeCalculator: FC<ISizeCalculator> = ({ onPropertyChanged, activeSyncedCollapseProp }) => {
  const mappingSize = useSizeMapping()
  const [syncedCollapsed, setSyncedCollapsed] = activeSyncedCollapseProp

  const [, setSizeModalActive, getSizeModalActiveSyncedProp] = useSyncedState<boolean>(false)

  return (
    <>
      {mappingSize && mappingSize.size_chart && (
        <div className="size-calculator">
          <style>{sizeStyle}</style>
          <div
            className="size-guide"
            onClick={() => {
              setSyncedCollapsed(!syncedCollapsed)
              logger.logProductEvent('size_fit')
            }}
          >
            <img src={iconPathBvf('size&fit.svg')} alt="size chart" />
            <div className="size-guide__text">Size and Fit guide</div>
          </div>
          <>
            <div className={`size-calculator__size ${!syncedCollapsed ? 'size-expand' : ''}`}>
              <div className="size-chart">
                <img src={iconPathBvf(mappingSize?.size_chart ?? '')} alt="size chart" />
              </div>
              {mappingSize?.visible && (
                <div
                  className="size-calculator__href"
                  onClick={() => {
                    setSizeModalActive(true)
                    logger.logProductEvent('size_calculator')
                  }}
                >
                  <img src={iconPathBvf('bra_caculator.svg')} alt="expands" />
                  <span className="size-calculator__href--content">
                    <span className="size-bold">Still need help?</span>
                    <span> Use our Size Calculator to find your ideal size</span>
                  </span>
                  <img
                    className="arror-icon"
                    src={iconPathBvf('chevron-right.svg')}
                    alt="arrow right"
                  />
                </div>
              )}
            </div>
            <SizeModal
              onPropertyChanged={onPropertyChanged}
              activeSyncedProp={getSizeModalActiveSyncedProp()}
            />
          </>
        </div>
      )}
    </>
  )
}

const sizeStyle = css.global`
  .size-calculator {
    margin-bottom: 24px;
    .size-guide {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      color: var(--primary-100);
      gap: 8px;
      cursor: pointer;
      img {
        width: 28px;
        height: 28px;
      }
      &__text {
        text-decoration-line: underline;
      }
    }
    .size-chart {
      display: flex;
      margin: 8px 0 10px;
      img {
        width: 100%;
        height: auto;
      }
    }
    &__href {
      padding: 12px;
      background: #fef7ec;
      border-radius: 4px;
      display: flex;
      cursor: pointer;
      gap: 8px;
      width: 100%;
      align-items: center;
      border: 1px solid transparent;

      &--content {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }

      .size-bold {
        font-weight: bold;
      }
      .arrow-icon {
        margin-left: auto;
      }

      &:hover {
        border: 1px solid #e8c998;
      }
    }
    &__size {
      max-height: 0;
      overflow: hidden;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      &.size-expand {
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        max-height: 1000px;
      }
    }

    @media screen and (min-width: 434px) {
      margin-bottom: 30px;
      .size-chart {
        img {
          max-width: 434px;
          height: auto;
        }
      }
      &__href {
        max-width: 434px;
      }
    }

    @media screen and (min-width: 768px) {
      margin-bottom: 32px;
      .size-chart {
        margin: 16px 0 16px;
      }
      &__href {
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
`
export default SizeCalculator
