import { FC, FormEvent, useState } from 'react'
import css from 'styled-jsx/css'
import StarRating from '../shared/StarRating'
import { useNotiContext, REMOVE_ALL_AND_ADD } from '@notify'
import { FileUploader } from '../shared/FileUploader'
// import { CartBvfService } from '@libs/client/order/services'
import { useSetting } from '@ui/hooks'
import { CartBvfService } from '@libs/client/order/services'

type ReviewsProps = {
  open: boolean
  toggleModal: () => void
}

const ReviewModal: FC<ReviewsProps> = ({ open = false, toggleModal }: ReviewsProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [rating, setRating] = useState(5)
  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const [review, setReview] = useState('')
  const { notiDispatch } = useNotiContext()
  const [reviewImagesBlob, setReviewImagesBlob] = useState<File[]>([])
  const [sellpageSetting] = useSetting('sellpage')

  const onImageChanged = (file: File[]) => {
    if (file && file?.length) {
      const arrayCheck = ['.jpg', '.jpeg', '.png', 'tiff', 'webp', 'gif']
      file.forEach((image) => {
        const nameFile = image?.name
        if (!arrayCheck.some((v) => nameFile.includes(v))) {
          notiDispatch({
            type: REMOVE_ALL_AND_ADD,
            payload: {
              type: 'is-danger',
              content: `Please upload .jpg / .jpeg / .png / .tiff / .webp / .gif file only`
            }
          })
        } else {
          setReviewImagesBlob([...reviewImagesBlob, image])
        }
      })
    }
  }

  const onSubmit = async (event: FormEvent<any>) => {
    event.preventDefault()
    const formData = {
      pageId: sellpageSetting?.settings?.general?.id || '',
      star: rating,
      name: name,
      title: title,
      review: review,
      // attachments data type is wrong
      attachments: (reviewImagesBlob || []) as any
    }

    setIsSubmitting(true)
    try {
      await CartBvfService.review(formData)
      setIsSubmitting(true)
      notiDispatch({
        type: REMOVE_ALL_AND_ADD,
        payload: {
          content: 'We love hearing from you! Thank you for leaving feedback for us.',
          type: 'is-primary',
          timeout: 10
        }
      })
      toggleModal && toggleModal()
    } catch (err) {
      console.error(err)
    } finally {
      setIsSubmitting(false)
      setRating(5)
      setName('')
      setTitle('')
      setReview('')
      setReviewImagesBlob([])
    }
  }
  return (
    <>
      <style jsx>{globalStyle}</style>
      <style jsx>{fileUploadStyle}</style>
      <div
        className={` modal slide-right ${open ? 'is-active' : ''}`}
        style={{
          visibility: open ? 'visible' : 'hidden'
        }}
      >
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head has-background-white">
            <p className="modal-card-title">Write your review</p>
            <button className="delete" aria-label="close" onClick={toggleModal} />
          </header>
          <section className="modal-card-body">
            <div className="modal-card-body--star">
              <StarRating
                className={starStyle.className}
                editable={true}
                rating={rating}
                onChange={(idx) => setRating(idx)}
              />
            </div>

            {starStyle.styles}
            <form onSubmit={onSubmit}>
              <div className="field">
                <label className="label">
                  Your name
                  <div className="control">
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter your name here"
                      type="text"
                      className="input"
                      required
                    />
                  </div>
                </label>
              </div>
              <div className="field">
                <label className="label">
                  Title
                  <div className="control">
                    <input
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Enter title here"
                      type="text"
                      className="input"
                    />
                  </div>
                </label>
              </div>
              <div className="field">
                <label className="label">
                  How was your experience?
                  <div className="control">
                    <textarea
                      value={review}
                      onChange={(e) => setReview(e.target.value)}
                      placeholder="Please share your experience with us"
                      className="textarea"
                    />
                  </div>
                </label>
              </div>
              <div className={`image-container`}>
                <div
                  className={`${
                    reviewImagesBlob?.length ? 'after-upload-boarder' : 'before-upload-boarder'
                  }`}
                >
                  <FileUploader
                    className="img-wrapper"
                    height="80px"
                    width="100%"
                    multiple={true}
                    acceptFileType="image/*"
                    showIcon={!reviewImagesBlob.length}
                    onFilesChanged={(f) => onImageChanged(f)}
                  />
                  {reviewImagesBlob?.length ? (
                    reviewImagesBlob?.map((image, key) => (
                      <img src={URL.createObjectURL(image)} key={key} alt="" />
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <button
                type="submit"
                className={`button--cta button is-primary is-fullwidth-mobile submit-btn-desktop ${
                  isSubmitting ? 'is-loading' : ''
                }`}
              >
                Submit
              </button>
            </form>
          </section>
        </div>
      </div>
    </>
  )
}

const globalStyle = css`
  .modal {
    z-index: 1000;

    .submit-btn-desktop {
      width: 100%;
    }

    .modal-card {
      height: 100vh;
      max-height: 100vh;
      max-width: 480px;
      margin-right: 0;

      @media screen and (max-width: 768px) {
        margin-left: 0;
        max-width: 100vw;
        width: 100vw;
      }

      .modal-card-head {
        border-bottom: 1px solid #e0e3e9;
        border-radius: 0;
        padding: 40px 30px 10px;

        @media screen and (max-width: 768px) {
          padding: 4px 16px;
        }

        .modal-card-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 28px;
          color: #363636;
        }

        .delete {
          font-size: 40px;
          height: 48px;
          max-height: 40px;
          max-width: 40px;
          min-height: 40px;
          min-width: 40px;
          width: 48px;
        }
      }

      .modal-card-body {
        &--star {
          display: flex;
          justify-content: center;
        }

        .label {
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;

          color: #363636;

          .input,
          .textarea {
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
          }
        }

        @media screen and (max-width: 768px) {
          padding: 24px 12px;
        }
      }
    }

    .field {
      @media screen and (min-width: 414px) {
        margin-bottom: 40px;
      }
      .input,
      .textarea {
        border: 1px solid #e0e3e9;
        border-radius: 4px;
        box-shadow: none;
        padding: 16px;

        margin-top: 8px;
      }
    }
  }
`

const fileUploadStyle = css`
  .image-container {
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 16px;

    .before-upload-boarder {
      border-radius: 8px;
      border: 3px dashed #e0e3e9;
      background-color: #f9fafb;
    }

    .after-upload-boarder {
      /* position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; */
      :global(.img-wrapper) {
        position: absolute;
        opacity: 0;
      }
    }

    img {
      height: 90px;
      width: 90px;
      object-fit: cover;
      border-radius: 8px;
    }
  }
`

const starStyle = css.resolve`
  .star-rating {
    @media screen and (max-width: 768px) {
      height: 40px;
      margin-bottom: 16px;
    }
  }
`

export default ReviewModal
